import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { request } from '../../utils';
import { Button, Upload, Cascader, Image, Select, DatePicker, Space, Form, Input, Popconfirm, Table, message, Modal, Typography } from 'antd';
import './index.scss'
import { EditTwoTone, PlusOutlined, DeleteTwoTone } from '@ant-design/icons'
import { SearchOutlined, UploadOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSelector } from 'react-redux'


//antdコンポーネントを導入する
const { Paragraph } = Typography;
const { Option } = Select;
const { TextArea } = Input;
// インターフェースに必要なパラメータ形式を返します
const getParams = (params) => ({

  ...params.pagination,
  ...params.filters,
  volWeightStart: params.filters?.volWeight?.[0] ? Number(params.filters?.volWeight?.[0]) : null,
  volWeightEnd: params.filters?.volWeight?.[1] ? Number(params.filters?.volWeight?.[1]) : null,
  volumeStart: params.filters?.volume?.[0] ? Number(params.filters?.volume?.[0]) : null,
  volumeEnd: params.filters?.volume?.[1] ? Number(params.filters?.volume?.[1]) : null,
  weightStart: (params.filters?.weight?.[0]) ? Number(params.filters?.weight?.[0]) : null,
  weightEnd: (params.filters?.weight?.[1]) ? Number(params.filters?.weight?.[1]) : null,
  proPriceStart: params.filters?.proPrice?.[0] ? Number(params.filters?.proPrice?.[0]) : null,
  proPriceEnd: params.filters?.proPrice?.[1] ? Number(params.filters?.proPrice?.[1]) : null,
  proShippingStart: params.filters?.proShipping?.[0] ? Number(params.filters?.proShipping?.[0]) : null,
  proShippingEnd: params.filters?.proShipping?.[1] ? Number(params.filters?.proShipping?.[1]) : null,
  proSumStart: params.filters?.proSum?.[0] ? Number(params.filters?.proSum?.[0]) : null,
  proSumEnd: params.filters?.proSum?.[1] ? Number(params.filters?.proSum?.[1]) : null,
  proInStockDateStart: params.filters?.proInStockDate?.[0] || null,
  proInStockDateEnd: params.filters?.proInStockDate?.[1] || null,
  madeDateStart: (params.filters?.madeDate?.[0]) ? (params.filters?.madeDate?.[0]) + "-01" : null,
  madeDateEnd: (params.filters?.madeDate?.[1]) ? (params.filters?.madeDate?.[1]) + "-01" : null,
  validityDateStart: (params.filters?.validityDate?.[0]) ? (params.filters?.validityDate?.[0]) + "-01" : null,
  validityDateEnd: (params.filters?.validityDate?.[1]) ? (params.filters?.validityDate?.[1]) + "-01" : null,
  proDateStart: params.filters?.proDate?.[0] || null,
  proDateEnd: params.filters?.proDate?.[1] || null,
  lightItems: params.filters?.lightItems?.[0] || null,
  updateTimeStart: params.filters?.updateTime?.[0] || null,
  updateTimeEnd: params.filters?.updateTime?.[1] || null,
  issDateStart: (params.filters?.issDate?.[0]) ? (params.filters?.issDate?.[0]) + "-01" : null,
  issDateEnd: (params.filters?.issDate?.[1]) ? (params.filters?.issDate?.[1]) + "-01" : null,
  shippingPriceCnStart: params.filters?.shippingPriceCn?.[0] ? Number(params.filters?.shippingPriceCn?.[0]) : null,
  shippingPriceCnEnd: params.filters?.shippingPriceCn?.[1] ? Number(params.filters?.shippingPriceCn?.[1]) : null,
  shippingPriceJpStart: params.filters?.shippingPriceJp?.[0] ? Number(params.filters?.shippingPriceJp?.[0]) : null,
  shippingPriceJpEnd: params.filters?.shippingPriceJp?.[1] ? Number(params.filters?.shippingPriceJp?.[1]) : null,


  updateShiPriceStart: params.filters?.updateShiPrice?.[0] ? Number(params.filters?.updateShiPrice?.[0]) : null,
  updateShiPriceEnd: params.filters?.updateShiPrice?.[1] ? Number(params.filters?.updateShiPrice?.[1]) : null,
  testPriceStart: params.filters?.testPrice?.[0] ? Number(params.filters?.testPrice?.[0]) : null,
  testPriceEnd: params.filters?.testPrice?.[1] ? Number(params.filters?.testPrice?.[1]) : null,
  priceSumCnStart: params.filters?.priceSumCn?.[0] ? Number(params.filters?.priceSumCn?.[0]) : null,
  priceSumCnEnd: params.filters?.priceSumCn?.[1] ? Number(params.filters?.priceSumCn?.[1]) : null,
  priceSumJpStart: params.filters?.priceSumJp?.[0] ? Number(params.filters?.priceSumJp?.[0]) : null,
  priceSumJpEnd: params.filters?.priceSumJp?.[1] ? Number(params.filters?.priceSumJp?.[1]) : null,

  testGroMarginStart: params.filters?.testGroMargin?.[0] ? Number(params.filters?.testGroMargin?.[0]) : null,
  testGroMarginEnd: params.filters?.testGroMargin?.[1] ? Number(params.filters?.testGroMargin?.[1]) : null,

  taxExcPriceStart: params.filters?.taxExcPrice?.[0] ? Number(params.filters?.taxExcPrice?.[0]) : null,
  taxExcPriceEnd: params.filters?.taxExcPrice?.[1] ? Number(params.filters?.taxExcPrice?.[1]) : null,

  estPriceStart: params.filters?.estPrice?.[0] ? Number(params.filters?.estPrice?.[0]) : null,
  estPriceEnd: params.filters?.estPrice?.[1] ? Number(params.filters?.estPrice?.[1]) : null,

  staPriceStart: params.filters?.staPrice?.[0] ? Number(params.filters?.staPrice?.[0]) : null,
  staPriceEnd: params.filters?.staPrice?.[1] ? Number(params.filters?.staPrice?.[1]) : null,

  groMarginStart: params.filters?.groMargin?.[0] ? Number(params.filters?.groMargin?.[0]) : null,
  groMarginEnd: params.filters?.groMargin?.[1] ? Number(params.filters?.groMargin?.[1]) : null,

  purPremiumStart: params.filters?.purPremium?.[0] ? Number(params.filters?.purPremium?.[0]) : null,
  purPremiumEnd: params.filters?.purPremium?.[1] ? Number(params.filters?.purPremium?.[1]) : null,

  pricePremiumStart: params.filters?.pricePremium?.[0] ? Number(params.filters?.pricePremium?.[0]) : null,
  pricePremiumEnd: params.filters?.pricePremium?.[1] ? Number(params.filters?.pricePremium?.[1]) : null,

  refPriceStart: params.filters?.refPrice?.[0] ? Number(params.filters?.refPrice?.[0]) : null,
  refPriceEnd: params.filters?.refPrice?.[1] ? Number(params.filters?.refPrice?.[1]) : null,
  appIntMarginStart: params.filters?.appIntMargin?.[0] ? Number(params.filters?.appIntMargin?.[0]) : null,
  appIntMarginEnd: params.filters?.appIntMargin?.[1] ? Number(params.filters?.appIntMargin?.[1]) : null,
  appInterestStart: params.filters?.appInterest?.[0] ? Number(params.filters?.appInterest?.[0]) : null,
  appInterestEnd: params.filters?.appInterest?.[1] ? Number(params.filters?.appInterest?.[1]) : null,
  wmIntMarginStart: params.filters?.wmIntMargin?.[0] ? Number(params.filters?.wmIntMargin?.[0]) : null,
  wmIntMarginEnd: params.filters?.wmIntMargin?.[1] ? Number(params.filters?.wmIntMargin?.[1]) : null,
  wmInterestStart: params.filters?.wmInterest?.[0] ? Number(params.filters?.wmInterest?.[0]) : null,
  wmInterestEnd: params.filters?.wmInterest?.[1] ? Number(params.filters?.wmInterest?.[1]) : null,
  wmInvIntMarginStart: params.filters?.wmInvIntMargin?.[0] ? Number(params.filters?.wmInvIntMargin?.[0]) : null,
  wmInvIntMarginEnd: params.filters?.wmInvIntMargin?.[1] ? Number(params.filters?.wmInvIntMargin?.[1]) : null,
  wmInvInterestStart: params.filters?.wmInvInterest?.[0] ? Number(params.filters?.wmInvInterest?.[0]) : null,
  wmInvInterestEnd: params.filters?.wmInvInterest?.[1] ? Number(params.filters?.wmInvInterest?.[1]) : null,
  agentPriceStart: params.filters?.agentPrice?.[0] ? Number(params.filters?.agentPrice?.[0]) : null,
  agentPriceEnd: params.filters?.agentPrice?.[1] ? Number(params.filters?.agentPrice?.[1]) : null,
  empPriceStart: params.filters?.empPrice?.[0] ? Number(params.filters?.empPrice?.[0]) : null,
  empPriceEnd: params.filters?.empPrice?.[1] ? Number(params.filters?.empPrice?.[1]) : null,
  appGroProMarginStart: params.filters?.appGroProMargin?.[0] ? Number(params.filters?.appGroProMargin?.[0]) : null,
  appGroProMarginEnd: params.filters?.appGroProMargin?.[1] ? Number(params.filters?.appGroProMargin?.[1]) : null,
  wmGroProMarginStart: params.filters?.wmGroProMargin?.[0] ? Number(params.filters?.wmGroProMargin?.[0]) : null,
  wmGroProMarginEnd: params.filters?.wmGroProMargin?.[1] ? Number(params.filters?.wmGroProMargin?.[1]) : null,

  kiYoShiStart: params.filters?.kiYoShi?.[0] ? Number(params.filters?.kiYoShi?.[0]) : null,
  kiYoShiEnd: params.filters?.kiYoShi?.[1] ? Number(params.filters?.kiYoShi?.[1]) : null,

  bicCameraStart: params.filters?.bicCamera?.[0] ? Number(params.filters?.bicCamera?.[0]) : null,
  bicCameraEnd: params.filters?.bicCamera?.[1] ? Number(params.filters?.bicCamera?.[1]) : null,

  yoDoBaShiStart: params.filters?.yoDoBaShi?.[0] ? Number(params.filters?.yoDoBaShi?.[0]) : null,
  yoDoBaShiEnd: params.filters?.yoDoBaShi?.[1] ? Number(params.filters?.yoDoBaShi?.[1]) : null,

  raKuTenStart: params.filters?.raKuTen?.[0] ? Number(params.filters?.raKuTen?.[0]) : null,
  raKuTenEnd: params.filters?.raKuTen?.[1] ? Number(params.filters?.raKuTen?.[1]) : null,

  yaHooStart: params.filters?.yaHoo?.[0] ? Number(params.filters?.yaHoo?.[0]) : null,
  yaHooEnd: params.filters?.yaHoo?.[1] ? Number(params.filters?.yaHoo?.[1]) : null,

  qooTenStart: params.filters?.qooTen?.[0] ? Number(params.filters?.qooTen?.[0]) : null,
  qooTenEnd: params.filters?.qooTen?.[1] ? Number(params.filters?.qooTen?.[1]) : null,

  officWebsiteStart: params.filters?.officWebsite?.[0] ? Number(params.filters?.officWebsite?.[0]) : null,
  officWebsiteEnd: params.filters?.officWebsite?.[1] ? Number(params.filters?.officWebsite?.[1]) : null,
  amazonStart: params.filters?.amazon?.[0] ? Number(params.filters?.amazon?.[0]) : null,
  amazonEnd: params.filters?.amazon?.[1] ? Number(params.filters?.amazon?.[1]) : null,
  tmallStart: params.filters?.tmall?.[0] ? Number(params.filters?.tmall?.[0]) : null,
  tmallEnd: params.filters?.tmall?.[1] ? Number(params.filters?.tmall?.[1]) : null,
  taobaoStart: params.filters?.taobao?.[0] ? Number(params.filters?.taobao?.[0]) : null,
  taobaoEnd: params.filters?.taobao?.[1] ? Number(params.filters?.taobao?.[1]) : null,
  wandouStart: params.filters?.wandou?.[0] ? Number(params.filters?.wandou?.[0]) : null,
  wandouEnd: params.filters?.wandou?.[1] ? Number(params.filters?.wandou?.[1]) : null,
  yangcongStart: params.filters?.yangcong?.[0] ? Number(params.filters?.yangcong?.[0]) : null,
  yangcongEnd: params.filters?.yangcong?.[1] ? Number(params.filters?.yangcong?.[1]) : null,
  yangmatouStart: params.filters?.yangmatou?.[0] ? Number(params.filters?.yangmatou?.[0]) : null,
  yangmatouEnd: params.filters?.yangmatou?.[1] ? Number(params.filters?.yangmatou?.[1]) : null,


});



const Home = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  //複数選択関数、selectedRows は現在選択されている行を保存するために使用される状態です
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editcategory, setEditcategory] = useState([]);

  const [addList, setAddList] = useState({ 'add': 0, 'edit': 0 }); // addList データの保存に使用される状態
  const [modalVisible, setModalVisible] = useState(false); // ウィンドウの表示状態を制御するために使用されます
  const [uploadKey, setUploadKey] = useState(null);

  // 編集権限
  const editPowerList = useSelector(state => state.user.userInfo.editPowerList);
  const userToken = useSelector(state => state.user.userInfo.token);
  const editPowerListArray = (editPowerList ? JSON.parse(editPowerList) : null);
  // 読む権限
  const viewPowerList = useSelector(state => state.user.userInfo.viewPowerList);
  const [windowHeight, setWindowHeight] = useState((window.innerHeight - 230));

  const handleResize = () => {
    setWindowHeight((window.innerHeight - 230));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // 解析編集権限
  const editPowerobject = editPowerListArray
    ? editPowerListArray.reduce((obj, str) => {
      obj[str] = true;
      return obj;
    }, {})
    : {};




  // 複数選択を解除
  const choosecancl = () => {
    setSelectedRowKeys([]);
    console.log(selectedRowKeys)
  }

  const isEditing = (record) => record.key === editingKey;
  // フィルタ情報
  const [filteredInfo, setFilteredInfo] = useState({});
  const [loading, setLoading] = useState(false);
  // クエリインターフェイス、入力パラメータの初期化
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
      position: ['topRight'],
    },
    agentPriceEnd: null,
    agentPriceStart: null,
    amazonEnd: null,
    amazonStart: null,
    appGroProMarginEnd: null,
    appGroProMarginStart: null,
    appIntMarginEnd: null,
    appIntMarginStart: null,
    appInterestEnd: null,
    appInterestStart: null,
    category: [null],
    current: null,
    delFlat: null,
    empPriceEnd: null,
    empPriceStart: null,
    fatherBrand: null,
    goodsImage: null,
    groMarginEnd: null,
    groMarginStart: null,
    id: null,
    inStockMethod: [null],
    lightItems: [null],
    janCode: null,
    madeDateEnd: null,
    madeDateStart: null,
    officWebsiteEnd: null,
    officWebsiteStart: null,
    oldJanCode: null,
    pageSize: null,
    priceSumEnd: null,
    priceSumStart: null,
    proDateEnd: null,
    proDateStart: null,
    proInStockDateEnd: null,
    proInStockDateStart: null,
    proPerson: [null],
    proPriceEnd: null,
    proPriceStart: null,
    proShippingEnd: null,
    proShippingStart: null,
    proSumEnd: null,
    proSumStart: null,
    productName: null,
    refUrl: null,
    saleStatus: [null],
    set: null,
    setContent: null,
    shippingPriceEnd: null,
    shippingPriceStart: null,
    sonBrand: null,
    speContext: null,
    speName: null,
    spuCode: null,
    staPriceEnd: null,
    staPriceStart: null,
    taobaoEnd: null,
    taobaoStart: null,
    tmallEnd: null,
    tmallStart: null,
    updateTimeEnd: null,
    updateTimeStart: null,
    updateUser: [null],
    validityDateEnd: null,
    validityDateStart: null,
    volWeightEnd: null,
    volWeightStart: null,
    volumeEnd: null,
    volumeStart: null,
    wandouEnd: null,
    wandouStart: null,
    weightEnd: null,
    weightStart: null,
    wmGroProMarginEnd: null,
    wmGroProMarginStart: null,
    wmIntMarginEnd: null,
    wmIntMarginStart: null,
    wmInterestEnd: null,
    wmInterestStart: null,
    wmInvIntMarginEnd: null,
    wmInvIntMarginStart: null,
    wmInvInterestEnd: null,
    wmInvInterestStart: null,
    yangcongEnd: null,
    yangcongStart: null,
    yangmatouEnd: null,
    yangmatouStart: null,
    a: 0,
  });

  const currentPageRef = useRef(tableParams);

  useEffect(() => {
    // 检测到状态更新后调用获取数据的函数
    console.log('jiance', tableParams);
    currentPageRef.current = tableParams;
  }, [tableParams]);
  // テーブルのフィルタ情報が変更された場合は、フィルタ情報を更新します。
  const handleChange = (pagination, filters) => {
    console.log(pagination)
    setFilteredInfo(filters);
    setTableParams({
      ...tableParams,
      pagination,
      filters,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }

  };
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {

    confirm();

  };


  const [data, setData] = useState([])
  const clearFilters = () => {
    console.log(filteredInfo);
    setFilteredInfo({});
  };
  // エクスポートインターフェイスを呼び出す
  const excelExport = async () => {
    try {
      // リクエストパラメータを作成
      const inforequest = { ...getParams(tableParams), ids: selectedRowKeys };
      request.post('/ocis/goods/exportExcel', inforequest, {
        // responseTypeオブジェクト形式をblobに設定
        responseType: "blob",
      }).then(res => {
        // ダウンロード用のリンクを作成
        const url = window.URL.createObjectURL(new Blob([res],
          // ファイルのmimeタイプを設定、ここでは.xlsx形式のmimeタイプ
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        // ヘッダーからサーバーが命名したファイル名を取得
        const fileName = '1213';
        link.setAttribute('download', 'filename.xlsx');
        document.body.appendChild(link);
        link.click();
        message.success('発行成功');
      });
    } catch (error) {
      console.error('Excelファイルのダウンロード中にエラーが発生しました:', error);
    }
  };

  const languageChange = (value) => {
    console.log(`selected ${value}`);
    const columnKeys = ['fatherBrand', 'sonBrand', 'productName', 'speName', 'speContext'];

    const updatedColumns = columns.map((group) => {
      if (group.children) {
        group.children = group.children.map((subgroup) => {
          if (subgroup.children) {
            subgroup.children = subgroup.children.map((column) => {
              const index = columnKeys.indexOf(column.dataIndex);
              if ((index !== -1) && (value == 'japanese')) {
                // 日本語に切り替え、必要な列を更新
                return {
                  ...column,
                  render: (text) => <div className='textleft'><div>{text?.jp ? text.jp : null}</div></div>,
                };
              } else if ((index !== -1) && (value == 'chinese')) {
                // 中国語に切り替え、必要な列を更新
                return {
                  ...column,
                  render: (text) => <div className='textleft'><div>{text?.cn ? text.cn : null}</div></div>,
                };
              } else if ((index !== -1) && (value == 'english')) {
                // 英語に切り替え、必要な列を更新
                return {
                  ...column,
                  render: (text) => <div className='textleft'><div>{text?.en ? text.en : null}</div></div>,
                };
              } else if ((index !== -1) && (value == 'all')) {
                // すべての言語を表示するように切り替え、必要な列を更新
                return {
                  ...column,
                  render: (text) => <div className='textleft'><div>{text?.jp ? text.jp : null}</div><div>{text?.cn ? text.cn : null}</div><div>{text?.en ? text.en : null}</div></div>,
                };
              }
              return column;
            });
          }
          return subgroup;
        });
      }
      return group;
    });

    setColumns(updatedColumns);
  };


  const getUploadExcel = async () => {
    try {
      request.get('/ocis/goods/downloadTemplate', {
        // responseTypeオブジェクト形式をblobに設定
        responseType: "blob",
      }).then(res => {
        // ダウンロード用のリンクを作成
        const url = window.URL.createObjectURL(new Blob([res],
          // ファイルのmimeタイプを設定、ここでは.xlsx形式のmimeタイプ
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        // ヘッダーからサーバーが命名したファイル名を取得
        const fileName = '1213';
        link.setAttribute('download', 'UploadDemo.xlsx');
        document.body.appendChild(link);
        link.click();
        message.success('発行成功');
      });

    } catch (error) {
      console.error('Excelファイルのダウンロード中にエラーが発生しました:', error);
    }
  };


  // ページ番号が変更されたときにクエリ インターフェイスを呼び出す
  useEffect(() => {
    async function getlist() {
      setLoading(true);
      const inforequest = getParams(tableParams);

      const res = await request.post('/ocis/goods/goodsList', inforequest)
      // const rescategory = await request.get('/ocis/goods/goodsTree')

      // const res = await axios.get('https://localhost:3004/data')
      // const res = await axios.get('https://192.168.1.14:8087/ocis/goods/goodsList')
      // 遍历列表，将字符串形式的JSON转换为对象
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {

          ...tableParams.pagination,
          total: res.data.total,
          showTotal: () => `共${res.data.total}件`,
          showSizeChanger: true,
          showQuickJumper: true,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },


      });

      const processedData = res.data.list.map(item => {
        return {
          ...item,

          fatherBrand: (item.fatherBrand ? JSON.parse(item.fatherBrand) : null),

          // ... 其他属性wwww
          volWeight: (item.volWeight ? JSON.parse(item.volWeight) : null),
          sonBrand: (item.sonBrand ? JSON.parse(item.sonBrand) : null),
          productName: (item.productName ? JSON.parse(item.productName) : null),
          speName: (item.speName ? JSON.parse(item.speName) : null),
          // categoryMenu: (item.speName ? JSON.parse(item.categoryMenu) : null),
          speContext: (item.speContext ? JSON.parse(item.speContext) : null),
          volume: (item.volume ? JSON.parse(item.volume) : null),
          // setContent: (item.setContent ? JSON.parse(item.setContent) : null)
        };
      });
      setData(processedData)
    }
    getlist()
    // }, [tableParams]);
  }, [JSON.stringify(tableParams)]);


  // レンダリング間隔検索ボタン
  const getColumnRangeSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,

        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          // onChange={(e) => console.log(e.target.value)}

          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value, selectedKeys[1]] : [])}
          onPressEnter={() => {
            // if (selectedKeys[0] && selectedKeys[1]) { // 检查输入值是否存在
            handleSearch(selectedKeys, confirm, dataIndex);
            // }
          }}
          style={{
            marginBottom: 8,
            display: 'inline-block',
            width: '60px',
          }}
        />--
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[1]}
          // onChange={(e) => console.log(e.target.value)}

          onChange={(e) => setSelectedKeys(e.target.value ? [selectedKeys[0], e.target.value] : [])}
          onPressEnter={() => {
            // if (selectedKeys[0] && selectedKeys[1]) { // 检查输入值是否存在
            handleSearch(selectedKeys, confirm, dataIndex);
            // }
          }}
          style={{
            marginBottom: 8,
            display: 'inline-block',
            width: '60px',
          }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => {
              // if (selectedKeys[0] && selectedKeys[1]) { // 检查输入值是否存在
              handleSearch(selectedKeys, confirm, dataIndex);
              // }
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 60,
            }}
          >
            確認
          </Button>
        </Space>
      </div>
    ),
    // 定义表头中筛选图标的渲染方式
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    // 判断某一行是否符合筛选条件


    // 再次打开后全选内容
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{
    //         backgroundColor: '#ffc069',
    //         padding: 0,
    //       }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });
  // 検索ボタンをレンダリングする
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys}
          onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : null)}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 60,
            }}
          >
            確認
          </Button>
        </Space>
      </div>
    ),
    // 定义表头中筛选图标的渲染方式
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,

        }}
      />
    ),
    // 判断某一行是否符合筛选条件
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

    // 再次打开后全选内容
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // 加上之后不能保存多列的筛选条件
    // //filteredValue: filteredInfo.dataIndex || null,
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{
    //         backgroundColor: '#ffc069',
    //         padding: 0,
    //       }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });
  // レンダリング期間検索ボタン
  const { RangePicker } = DatePicker;
  const getColumnDateSearchProps = (dataIndex) => ({
    width: 60,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Space>
          <RangePicker
            format={"YYYY-MM-DD"}
            onChange={(dates) => {
              if (dates && dates.length === 2) {
                // 提取开始和结束日期，并格式化为字符串
                const [startDate, endDate] = dates;
                const formattedStartDate = startDate.format("YYYY-MM-DD");
                const formattedEndDate = endDate.format("YYYY-MM-DD");

                // 使用 setSelectedKeys 存储格式化后的日期范围
                setSelectedKeys([formattedStartDate, formattedEndDate]);
              } else {
                // 如果没有选择日期范围，可以将 setSelectedKeys 设置为空数组或其他默认值
                setSelectedKeys([]);
              }
            }}
            allowClear={true}
          />
        </Space>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });
  //月間隔検索ボタンを描画します

  const getColumnMonthSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Space>
          <RangePicker picker="month"
            format={"YYYY-MM"}
            onChange={(dates) => {
              if (dates && dates.length === 2) {
                // 提取开始和结束日期，并格式化为字符串
                const [startDate, endDate] = dates;
                const formattedStartDate = startDate.format("YYYY-MM");
                const formattedEndDate = endDate.format("YYYY-MM");

                // 使用 setSelectedKeys 存储格式化后的日期范围
                setSelectedKeys([formattedStartDate, formattedEndDate]);
              } else {
                // 如果没有选择日期范围，可以将 setSelectedKeys 设置为空数组或其他默认值
                setSelectedKeys([]);
              }
            }}
            allowClear={true}
          />
        </Space>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });
  // テーブルパラメータ
  const [columns, setColumns] = useState(
    [
      // 出品固定列
      {
        title: (
          <div className=" thbgc-first">

            出品<CaretLeftOutlined onClick={() => handleWidthChange(['inStockMethod', 'saleStatus',]
              , [39, 39])} />
            <CaretRightOutlined onClick={() => handleWidthreturn(['inStockMethod', 'saleStatus',]
              , [46, 46])} />
          </div>
        ),

        // className: 'custom-header',
        children: [
          {
            title: (
              <div className=" thbgc-first">
                出品
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-first childrenHeight">
                    縮図
                  </div>
                ),
                dataIndex: 'goodsImage',
                key: 'goodsImage',
                width: 60,
                fixed: 'left',

                render: (text) =>
                  <div className="image-container">
                    <Image
                      // src="https://192.168.1.14/images/2024/1706253965470.jpg"
                      src={`${text}?t=${new Date().getTime()}`}
                      fallback="https://m.oneslifejapan.co.jp/images/2024/noimg.jpg"
                    />
                  </div>,


              },
              {
                title: (
                  <div className=" thbgc-first childrenHeight">
                    JAN Code
                  </div>
                ),
                dataIndex: 'janCode',
                key: 'janCode',
                width: 50,
                fixed: 'left',
                // 
                // 一件清除晒寻条件时要用
                // filteredValue: filteredInfo.janCode || null,
                ...getColumnSearchProps('janCode'),
                originalRender: text => <div className='textleft'>{text}</div>, // 保留原始渲染函数

              },



            ]
          },
          {
            title: (
              <div className=" thbgc-first">
                出品
                <CaretLeftOutlined onClick={() => handleWidthChange(['inStockMethod', 'saleStatus',]
                  , [39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['inStockMethod', 'saleStatus',]
                  , [46, 46])} />
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-first childrenHeight">
                    在庫状況
                    <CaretLeftOutlined onClick={() => handleWidthChange(['inStockMethod']
                      , [39])} />
                  </div>
                ),

                dataIndex: 'inStockMethod',
                key: 'inStockMethod',
                width: 46,
                fixed: 'left',

                //filteredValue: filteredInfo.inStockMethod || null,
                filters: [
                  {
                    text: '大量',
                    value: 1,
                  },
                  {
                    text: '少量',
                    value: 2,
                  },
                  {
                    text: '無し',
                    value: 3,
                  },
                  {
                    text: '未記録',
                    value: 4,
                  },
                ],
                filterMode: 'tree',
                // //filteredValue: filteredInfo.inStockMethod || null,
                originalRender: (text) => <div className='textleft'>{text === 1 ? "大量" : (text === 2 ? <div style={{ color: '#BF8F00' }}>少量</div> : (text === 3 ? <div style={{ color: '#ff0000' }}>無し</div> : '未記録'))}</div>,

                render: (text) => <div className='textleft'>{text === 1 ? "大量" : (text === 2 ? <div style={{ color: '#BF8F00' }}>少量</div> : (text === 3 ? <div style={{ color: '#ff0000' }}>無し</div> : '未記録'))}</div>,

              },
              {
                title: (
                  <div className=" thbgc-first childrenHeight">
                    出品状況
                    <CaretLeftOutlined onClick={() => handleWidthChange(['saleStatus']
                      , [39])} />
                  </div>
                ),
                width: 46,
                fixed: 'left',
                dataIndex: 'saleStatus',
                key: 'saleStatus',

                filters: [
                  {
                    text: '出品中',
                    value: 1,
                  },
                  {
                    text: '未出品',
                    value: 2,
                  },
                  {
                    text: '中止',
                    value: 3,
                  },
                  {
                    text: '指名買う',
                    value: 4,
                  },
                  {
                    text: '未記録',
                    value: 5,
                  },

                ],
                filterMode: 'tree',
                // //filteredValue: filteredInfo.inStockMethod || null,
                originalRender: (text) => <div className='textleft'>{text === 1 ? "出品中" : (text === 2 ? <div style={{ color: '#BF8F00' }}>未出品</div> : (text === 3 ? <div style={{ color: '#ff0000' }}>中止</div> : (text === 4 ? <div style={{ color: '#2981e6' }}>指名買う</div> : '未記録')))}</div>,

                render: (text) => <div className='textleft'>{text === 1 ? "出品中" : (text === 2 ? <div style={{ color: '#BF8F00' }}>未出品</div> : (text === 3 ? <div style={{ color: '#ff0000' }}>中止</div> : (text === 4 ? <div style={{ color: '#2981e6' }}>指名買う</div> : '未記録')))}</div>,
                //filteredValue: filteredInfo.saleStatus || null,
              },


            ]
          },

        ]


      },
      // 商品固定列
      {
        title: (
          <div className=" thbgc-second">
            商品
            <CaretLeftOutlined onClick={() => handleWidthChange(['fatherBrand']
              , [39])} />
            <CaretRightOutlined onClick={() => handleWidthreturn(['fatherBrand']
              , [60])} />
          </div>

        ),

        children: [
          {
            title: (
              <div className=" thbgc-second">
                商品
                <CaretLeftOutlined onClick={() => handleWidthChange(['fatherBrand']
                  , [39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['fatherBrand']
                  , [60])} />
              </div>
            ),

            children: [
              {
                title: (
                  <div className=" thbgc-second childrenHeight">
                    主ブランド
                    <CaretLeftOutlined onClick={() => handleWidthChange(['fatherBrand']
                      , [39])} />
                  </div>
                ),
                width: 60,
                dataIndex: 'fatherBrand',
                key: 'fatherBrand',

                fixed: 'left',
                // filteredValue: filteredInfo.fatherBrand || null,
                originalRender: (text) => <div className='textleft'><div>{text?.jp ? text.jp : null}</div><div>{text?.cn ? text.cn : null}</div><div>{text?.en ? text.en : null}</div></div>,

                render: (text) => <div className='textleft'><div>{text?.jp ? text.jp : null}</div><div>{text?.cn ? text.cn : null}</div><div>{text?.en ? text.en : null}</div></div>,
                ...getColumnSearchProps('fatherBrand'),
                // className: 'custom-header',

              },
              {
                title: (
                  <div className=" thbgc-second childrenHeight">
                    従ブランド
                  </div>
                ),
                width: 60,
                dataIndex: 'sonBrand',
                //filteredValue: filteredInfo.sonBrand || null,

                key: 'sonBrand',
                fixed: 'left',
                render: (text) => <div className='textleft'><div>{text?.jp ? text.jp : null}</div><div>{text?.cn ? text.cn : null}</div><div>{text?.en ? text.en : null}</div></div>,
                ...getColumnSearchProps('sonBrand'),
              },
              {
                title: (
                  <div className=" thbgc-second childrenHeight">
                    商品名
                  </div>
                ),
                dataIndex: 'productName',
                //filteredValue: filteredInfo.productName || null,
                key: 'productName',
                width: 100,
                fixed: 'left',
                render: (text) => <div className='textleft'><div>{text?.jp ? text.jp : null}</div><div>{text?.cn ? text.cn : null}</div><div>{text?.en ? text.en : null}</div></div>,
                ...getColumnSearchProps('productName'),
              },
              {
                title: (
                  <div className=" thbgc-second childrenHeight">
                    仕様
                  </div>
                ),
                dataIndex: 'speName',
                key: 'speName',
                width: 60,
                fixed: 'left',
                //filteredValue: filteredInfo.speName || null,
                render: (text) => <div className='textleft'><div>{text?.jp ? text.jp : null}</div><div>{text?.cn ? text.cn : null}</div><div>{text?.en ? text.en : null}</div></div>,

                ...getColumnSearchProps('speName'),
              },
              {
                title: (
                  <div className=" thbgc-second childrenHeight">
                    内容量
                  </div>
                ),
                dataIndex: 'speContext',
                key: 'speContext',
                width: 100,
                fixed: 'left',
                //filteredValue: filteredInfo.speContext || null,
                render: (text) => <div className='textleft'><div>{text?.jp ? text.jp : null}</div><div>{text?.cn ? text.cn : null}</div><div>{text?.en ? text.en : null}</div></div>,
                ...getColumnSearchProps('speContext'),
              },


            ]
          },


        ],
      },
      // 商品詳細
      {
        title: (
          <div className=" thbgc-third">

            商品詳細
            <CaretLeftOutlined onClick={() => handleWidthChange(['setContent', 'volume'
              , 'volWeight', 'weight', 'firstMenu', 'secondMenu', 'thirdMenu',]
              , [39, 39, 39, 39, 39, 39, 39])} />
            <CaretRightOutlined onClick={() => handleWidthreturn(['setContent', 'volume'
              , 'volWeight', 'weight', 'firstMenu', 'secondMenu', 'thirdMenu',]
              , [110, 100, 60, 60, 60, 60, 60])} />
          </div>
        ),

        // className: 'custom-header',
        children: [
          {
            title: (
              <div className=" thbgc-third">
                セット
                <CaretLeftOutlined onClick={() => handleWidthChange(['setContent',]
                  , [39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['setContent',]
                  , [110])} />
              </div>
            ),

            children: [
              {
                title: (
                  <div className=" thbgc-third childrenHeight">
                    記号
                  </div>
                ),
                width: 30,
                dataIndex: 'set',
                key: 'set',
                // fixed: 'left',
                // filteredValue: filteredInfo.fatherBrand || null,
                filters: [
                  {
                    text: 'あり',
                    value: 1,
                  },
                  {
                    text: '無し',
                    value: 2,
                  },

                ],
                filterMultiple: false,
                render: (text) => <div className='textleft'>{text === 1 ? 'セ' : ''}</div>,
                // ...getColumnSearchProps('set'),
                // className: 'custom-header',

              },
              {
                title: (
                  <div className=" thbgc-third childrenHeight">
                    セット内容
                    <CaretLeftOutlined onClick={() => handleWidthChange(['setContent']
                      , [39])} />
                  </div>
                ),
                width: 110,
                dataIndex: 'setContent',
                key: 'setContent',
                // fixed: 'left',

                // filteredValue: filteredInfo.fatherBrand || null,
                originalRender: (text) => <div className='textleft' style={{ whiteSpace: 'pre-wrap' }} rows={4}>{text}</div>,

                render: (text) => <div className='textleft' style={{ whiteSpace: 'pre-wrap' }} rows={4}>{text}</div>,
                ...getColumnSearchProps('setContent'),
                // className: 'custom-header',

              },





            ]
          },
          {
            title: (
              <div className=" thbgc-third">
                重量
                <CaretLeftOutlined onClick={() => handleWidthChange(['volume'
                  , 'volWeight', 'weight']
                  , [39, 39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['volume'
                  , 'volWeight', 'weight']
                  , [120, 50, 50])} />
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-third childrenHeight">
                    サイズcm
                    <CaretLeftOutlined onClick={() => handleWidthChange(['volume']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'volume',
                key: 'volume',
                width: 100,

                //filteredValue: filteredInfo.volume || null,
                originalRender: (text) => <div className='textleft'><div>{text?.length ? parseFloat(text.length).toFixed(1) : 0}*{text?.width ? parseFloat(text.width).toFixed(1) : 0}*{text?.height ? parseFloat(text.height).toFixed(1) : 0}cm</div>
                  <div>{text?.volume ? parseFloat(text.volume).toFixed(1) : 0}cm³</div></div>,
                render: (text) => <div className='textleft'><div>{text?.length ? parseFloat(text.length).toFixed(1) : 0}*{text?.width ? parseFloat(text.width).toFixed(1) : 0}*{text?.height ? parseFloat(text.height).toFixed(1) : 0}cm</div>
                  <div>{text?.volume ? parseFloat(text.volume).toFixed(1) : 0}cm³</div></div>,

                ...getColumnRangeSearchProps('volume'),
              },
              {
                title: (
                  <div className=" thbgc-third childrenHeight">
                    体積重量kg
                    <CaretLeftOutlined onClick={() => handleWidthChange(['volWeight']
                      , [39])} />
                  </div>
                ),

                dataIndex: 'volWeight',
                key: 'volWeight',

                width: 50,
                //filteredValue: filteredInfo.volWeight || null,
                // render: (text) =>
                //   <div><div>{text?.weight}{text?.type === '1' ? <span>泡重</span> : []}</div>

                //   </div>
                // ,
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                ...getColumnRangeSearchProps('volWeight'),
              },
              {
                title: (
                  <div className=" thbgc-third childrenHeight">
                    正味重量kg
                    <CaretLeftOutlined onClick={() => handleWidthChange(['weight']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'weight',
                key: 'weight',

                width: 50,
                //filteredValue: filteredInfo.weight || null,
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                ...getColumnRangeSearchProps('weight'),
              },
              {
                title: (
                  <div className=" thbgc-third childrenHeight">
                    軽い品物
                  </div>
                ),
                dataIndex: 'lightItems',
                key: 'lightItems',
                width: 30,

                filters: [
                  {
                    text: '軽',
                    value: 1,

                  },
                  {
                    text: '重',
                    value: 2,
                  },
                ],
                filterMultiple: false,
                originalRender: (text) => <div className='textleft'>{text}</div>,
                render: (text) => <div className='textleft'>{text}</div>
                // filterMode: 'tree',
                //filteredValue: filteredInfo.weight || null,
                // ...getColumnRangeSearchProps('weight'),
              },

            ]
          },
          {
            title: (
              <div className=" thbgc-third">
                カテゴリ
                <CaretLeftOutlined onClick={() => handleWidthChange(['firstMenu', 'secondMenu', 'thirdMenu',]
                  , [39, 39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['firstMenu', 'secondMenu', 'thirdMenu',]
                  , [60, 60, 60])} />
              </div>
            ),
            dataIndex: 'category',
            //filteredValue: filteredInfo.category || null,


            children: [{
              title: (
                <div className=" thbgc-third childrenHeight">
                  カテゴリⅠ
                  <CaretLeftOutlined onClick={() => handleWidthChange(['firstMenu']
                    , [39])} />
                </div>
              ),

              dataIndex: 'firstMenu',

              //filteredValue: filteredInfo.firstMenu || null,

              key: 'firstMenu',
              width: 60,
              originalRender: (text) => <div className='textleft'>{text}</div>,
              render: (text) => <div className='textleft'>{text}</div>

            },
            {
              title: (
                <div className=" thbgc-third childrenHeight">
                  カテゴリⅡ
                  <CaretLeftOutlined onClick={() => handleWidthChange(['secondMenu']
                    , [39])} />
                </div>
              ),
              dataIndex: 'secondMenu',

              //filteredValue: filteredInfo.secondMenu || null,

              key: 'secondMenu',
              width: 60,
              originalRender: (text) => <div className='textleft'>{text}</div>,
              render: (text) => <div className='textleft'>{text}</div>

            },
            {
              title: (
                <div className=" thbgc-third childrenHeight
              ">
                  カテゴリⅢ
                  <CaretLeftOutlined onClick={() => handleWidthChange(['thirdMenu']
                    , [39])} />
                </div>
              ),
              dataIndex: 'thirdMenu',
              key: 'thirdMenu',

              width: 60,
              originalRender: (text) => <div className='textleft'>{text}</div>,
              render: (text) => <div className='textleft'>{text}</div>
              //filteredValue: filteredInfo.thirdMenu || null,

            },],
            // filterSearch: true,
            filters: [
              {
                text: 'Joe',
                value: 'Joe',
                children: [
                  {
                    text: 'Yellow',
                    value: 'Yellow',
                    children: [
                      {
                        text: 'adddasd5582asd13',
                        value: 'adddasd5582asd13',
                      },
                      {
                        text: 'blue1',
                        value: 'blue1',
                      },
                    ]
                  },
                  {
                    text: 'Yellow1',
                    value: 'Yellow1',
                  },
                ]
              },
              {
                text: 'Jim',
                value: 'Jim',
                children: [
                  {
                    text: 'Yellow2',
                    value: 'Yellow2',
                    children: [
                      {
                        text: 'blue2',
                        value: 'blue2',
                      },
                      {
                        text: 'blue12',
                        value: 'blue12',
                      },
                    ]
                  },
                  {
                    text: 'Yellow12',
                    value: 'Yellow12',
                  },
                ]
              },
            ],
            // defaultExpandAll: false,
            // treeDefaultExpandAll: false,
            filterMode: 'tree',
            // filterDropdownVisible: true,
            // filterDropdown: null,
            // filterSearch: true,
            // onFilter: (value, record) => record.thirdMenu.includes(value),
            // //filteredValue: filteredInfo.category || null,


          },
        ]


      },
      // 費用
      {
        title: (
          <div className=" thbgc-fourth">
            仕入
            <CaretLeftOutlined onClick={() => handleWidthChange(['proPrice'
              , 'proShipping', 'proPerson', 'proDate',]
              , [39, 39, 39, 39])} />
            <CaretRightOutlined onClick={() => handleWidthreturn(['proPrice'
              , 'proShipping', 'proPerson', 'proDate',]
              , [60, 60, 60, 60])} />
          </div>
        ),

        // className: 'custom-header',
        children: [
          {
            title: (
              <div className=" thbgc-fourth">
                仕入費用
                <CaretLeftOutlined onClick={() => handleWidthChange(['proPrice'
                  , 'proShipping']
                  , [39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['proPrice'
                  , 'proShipping']
                  , [60, 60])} />
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-fourth childrenHeight">
                    仕入価格JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['proPrice']
                      , [39])} />
                  </div>
                ),

                dataIndex: 'proPrice',

                width: 60,
                //filteredValue: filteredInfo.proPrice || null,
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                ...getColumnRangeSearchProps('proPrice'),
              },
              {
                title: (
                  <div className=" thbgc-fourth childrenHeight">
                    仕入送料JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['proShipping']
                      , [39])} />
                  </div>
                ),
                //filteredValue: filteredInfo.proShipping || null,

                dataIndex: 'proShipping',
                width: 60,

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                ...getColumnRangeSearchProps('proShipping'),

              },

              {
                title: (
                  <div className=" thbgc-fourth childrenHeight">
                    仕入累計JPY

                  </div>
                ),
                //filteredValue: filteredInfo.proSum || null,

                dataIndex: 'proSum',
                width: 60,

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                ...getColumnRangeSearchProps('proSum'),

              },

            ]
          },
          {
            title: (
              <div className=" thbgc-fourth">
                仕入情報
                <CaretLeftOutlined onClick={() => handleWidthChange(['proPerson', 'proDate',]
                  , [39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['proPerson', 'proDate',]
                  , [60, 60])} />
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-fourth childrenHeight">
                    仕入者
                    <CaretLeftOutlined onClick={() => handleWidthChange(['proPerson']
                      , [39])} />
                  </div>
                ),

                dataIndex: 'proPerson',
                key: 'proPerson',
                width: 60,

                filters: [
                  {
                    text: '大貫',
                    value: '大貫',
                  },
                  {
                    text: '麗沢',
                    value: '麗沢',
                  },
                  {
                    text: 'その他',
                    value: 'その他',
                  },
                  {
                    text: '未記録',
                    value: '未記録',
                  }
                ],
                filterMode: 'tree',
                originalRender: (text) => <div className='textleft'>{text}</div>,
                render: (text) => <div className='textleft'>{text}</div>
                //filteredValue: filteredInfo.proPerson || null,


              },
              {
                title: (
                  <div className=" thbgc-fourth childrenHeight">
                    仕入時間
                    <CaretLeftOutlined onClick={() => handleWidthChange(['proDate']
                      , [39])} />
                  </div>
                ),

                dataIndex: 'proDate',
                width: 55,

                //filteredValue: filteredInfo.proDate || null,
                originalRender: (text) => <div className='textleft'>{text}</div>,
                render: (text) => <div className='textleft'>{text}</div>,
                ...getColumnDateSearchProps("proDate"),

              },
              {
                title: (
                  <div className=" thbgc-fourth childrenHeight">
                    製造日
                  </div>
                ),
                //filteredValue: filteredInfo.madeDate || null,

                dataIndex: 'madeDate',
                width: 60,

                ...getColumnMonthSearchProps("madeDate"),
                originalRender: (text) => <div className='textleft'>{text}</div>,
                render: (text) => <div className='textleft'>{text}</div>
              },
              {
                title: (
                  <div className=" thbgc-fourth childrenHeight">
                    有効期限切れ
                  </div>
                ),
                //filteredValue: filteredInfo.validityDate || null,

                dataIndex: 'validityDate',
                width: 60,
                // 
                ...getColumnMonthSearchProps("validityDate"),
                originalRender: (text) => <div className='textleft'>{text}</div>,
                render: (text) => <div className='textleft'>{text}</div>
              },
            ]
          },

        ]


      },
      // 販売列
      {
        title: (
          <div className=" thbgc-fifth">

            中国向販売
            <CaretLeftOutlined onClick={() => handleWidthChange(["shippingPriceCn", "updateShiPrice", "testPrice", "testGroMargin", "estPrice", "taxExcPrice", "appIntMargin", "appInterest", "wmIntMargin", "wmInterest", "wmInvIntMargin", "wmInvInterest", "agentPrice", "empPrice"]
              , [39, 39, 39, 39, 39, 39, 39, 39, 39, 39, 39, 39, 39, 39,])} />
            <CaretRightOutlined onClick={() => handleWidthreturn(["shippingPriceCn", "updateShiPrice", "testPrice", "testGroMargin", "estPrice", "taxExcPrice", "appIntMargin", "appInterest", "wmIntMargin", "wmInterest", "wmInvIntMargin", "wmInvInterest", "agentPrice", "empPrice"]
              , [60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60,])} />
          </div>
        ),

        // className: 'custom-header',
        children: [
          {
            title: (
              <div className=" thbgc-fifth">
                物流
                <CaretLeftOutlined onClick={() => handleWidthChange(["shippingPriceCn", "updateShiPrice", "testPrice"]
                  , [39, 39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(["shippingPriceCn", "updateShiPrice", "testPrice"]
                  , [60, 60, 60])} />
              </div>
            ),


            children: [
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    測算物流割当JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['shippingPriceCn']
                      , [39])} />
                  </div>
                ),
                //filteredValue: filteredInfo.shippingPrice || null,

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,

                dataIndex: 'shippingPriceCn',
                width: 60,

                // ...getColumnRangeSearchProps('shippingPriceCn'),
              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    修正物流割当JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['updateShiPrice']
                      , [39])} />
                  </div>
                ),
                //filteredValue: filteredInfo.updateShiPrice || null,

                width: 60,
                dataIndex: 'updateShiPrice',
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                ...getColumnRangeSearchProps('updateShiPrice'),
              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    測算物流費用率%
                    <CaretLeftOutlined onClick={() => handleWidthChange(['testPrice']
                      , [39])} />
                  </div>
                ),
                width: 60,

                //filteredValue: filteredInfo.testPrice || null,

                dataIndex: 'testPrice',
                originalRender: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                render: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                ...getColumnRangeSearchProps('testPrice'),
              },

            ],
          },
          {
            title: (
              <div className=" thbgc-fifth">
                費用

              </div>
            ),


            children: [
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    総費用JPY
                  </div>
                ),
                //filteredValue: filteredInfo.priceSum || null,

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                dataIndex: 'priceSumCn',
                width: 60,
                ...getColumnRangeSearchProps('priceSumCn'),

              },

            ],

          },
          {
            title: (
              <div className=" thbgc-fifth">
                価格測算
                <CaretLeftOutlined onClick={() => handleWidthChange(["testGroMargin", "estPrice"]
                  , [39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(["testGroMargin", "estPrice"]
                  , [60, 60])} />
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    測算粗利益率%
                    <CaretLeftOutlined onClick={() => handleWidthChange(['testGroMargin']
                      , [39])} />
                  </div>
                ),
                width: 60,

                //filteredValue: filteredInfo.testGroMargin || null,
                originalRender: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                render: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                dataIndex: 'testGroMargin',
                ...getColumnRangeSearchProps('testGroMargin'),
              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    測算価格JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['estPrice']
                      , [39])} />
                  </div>
                ),
                width: 60,
                //filteredValue: filteredInfo.estPrice || null,

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                dataIndex: 'estPrice',
                ...getColumnRangeSearchProps('estPrice'),
              },
            ]
          },
          {
            title: (
              <div className=" thbgc-fifth-children">
                標準価格
                <CaretLeftOutlined onClick={() => handleWidthChange(["taxExcPrice"]
                  , [39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(["taxExcPrice"]
                  , [60])} />
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-fifth-children childrenHeight">
                    税<span style={{ color: "red" }}>別</span>価格JPY
                  </div>
                ),
                width: 60,

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                //filteredValue: filteredInfo.taxExcPrice || null,

                dataIndex: 'taxExcPrice',
                ...getColumnRangeSearchProps('taxExcPrice'),
              },
              {
                title: (
                  <div className=" thbgc-fifth-children childrenHeight">
                    税<span style={{ color: "red" }}>込</span>価格JPY
                  </div>
                ),
                width: 60,
                //filteredValue: filteredInfo.staPrice || null,

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                dataIndex: 'staPrice',
                ...getColumnRangeSearchProps('staPrice'),

              },
              {
                title: (
                  <div className=" thbgc-fifth-children childrenHeight">
                    粗利益率%
                  </div>
                ),
                dataIndex: 'groMargin',
                //filteredValue: filteredInfo.groMargin || null,
                originalRender: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                render: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                width: 60,
                ...getColumnRangeSearchProps('groMargin'),

              },
            ]
          },
          {
            title: (
              <div className=" thbgc-fifth">
                流通価格
                <CaretLeftOutlined onClick={() => handleWidthChange(["appIntMargin", "appInterest", "wmIntMargin", "wmInterest", "wmInvIntMargin", "wmInvInterest", "agentPrice", "empPrice"]
                  , [39, 39, 39, 39, 39, 39, 39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(["appIntMargin", "appInterest", "wmIntMargin", "wmInterest", "wmInvIntMargin", "wmInvInterest", "agentPrice", "empPrice"]
                  , [60, 60, 60, 60, 60, 60, 60, 60])} />
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    APP小売利益率%
                    <CaretLeftOutlined onClick={() => handleWidthChange(['appIntMargin']
                      , [39])} />
                  </div>
                ),
                width: 60,

                dataIndex: 'appIntMargin',
                originalRender: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                render: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                ...getColumnRangeSearchProps('appIntMargin'),

                //filteredValue: filteredInfo.appIntMargin || null,

              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    APP内販売者の利益JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['appInterest']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'appInterest',

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                //filteredValue: filteredInfo.appInterest || null,

                width: 60,
                ...getColumnRangeSearchProps('appInterest'),

              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    WC微客top利益率%
                    <CaretLeftOutlined onClick={() => handleWidthChange(['wmIntMargin']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'wmIntMargin',

                //filteredValue: filteredInfo.wmIntMargin || null,

                width: 70,
                originalRender: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(5).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(5).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                render: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(5).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(5).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                ...getColumnRangeSearchProps('wmIntMargin'),

              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    WC微客top利益JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['wmInterest']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'wmInterest',

                //filteredValue: filteredInfo.wmInterest || null,

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                width: 60,
                ...getColumnRangeSearchProps('wmInterest'),

              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    WC邀請人top利益率%
                    <CaretLeftOutlined onClick={() => handleWidthChange(['wmInvIntMargin']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'wmInvIntMargin',

                originalRender: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                render: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                //filteredValue: filteredInfo.wmInvIntMargin || null,

                width: 70,
                ...getColumnRangeSearchProps('wmInvIntMargin'),

              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    WC邀請人top利益JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['wmInvInterest']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'wmInvInterest',

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>,
                //filteredValue: filteredInfo.wmInvInterest || null,

                width: 60,
                ...getColumnRangeSearchProps('wmInvInterest'),

              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    販売代理の決済価格JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['agentPrice']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'agentPrice',

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                //filteredValue: filteredInfo.agentPrice || null,

                width: 60,
                ...getColumnRangeSearchProps('agentPrice'),

              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    ガイドさん持帰価格JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['empPrice']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'empPrice',

                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                //filteredValue: filteredInfo.empPrice || null,

                width: 60,
                ...getColumnRangeSearchProps('empPrice'),

              },
            ]
          },



        ]


      },

      // 参考情報
      {
        title: (
          <div className=" thbgc-seventh">

            参考情報
            <CaretLeftOutlined onClick={() => handleWidthChange(['appGroProMargin'
              , 'wmGroProMargin', 'purPremium', 'pricePremium', 'refUrl'
              , 'refPrice', 'issDate', 'kiYoShi', 'bicCamera', 'yoDoBaShi']
              , [39, 39, 39, 39, 39, 39, 39, 39, 39, 39])} />
            <CaretRightOutlined onClick={() => handleWidthreturn(['appGroProMargin'
              , 'wmGroProMargin', 'purPremium', 'pricePremium', 'refUrl'
              , 'refPrice', 'issDate', 'kiYoShi', 'bicCamera', 'yoDoBaShi']
              , [60, 60, 60, 60, 60, 60, 60, 60, 60, 60])} />
          </div>
        ),

        // className: 'custom-header',
        children: [
          {
            title: (
              <div className=" thbgc-seventh">
                販促測算
                <CaretLeftOutlined onClick={() => handleWidthChange(['appGroProMargin'
                  , 'wmGroProMargin']
                  , [39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['appGroProMargin'
                  , 'wmGroProMargin']
                  , [60, 60])} />
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-seventh childrenHeight">
                    APP利用可能粗利益率%
                    <CaretLeftOutlined onClick={() => handleWidthChange(['appGroProMargin']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'appGroProMargin',
                width: 60,

                originalRender: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                render: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                ...getColumnRangeSearchProps('appGroProMargin'),
                //filteredValue: filteredInfo.appGroProMargin || null,


              },
              {
                title: (
                  <div className=" thbgc-seventh childrenHeight">
                    WM利用可能粗利益率%
                    <CaretLeftOutlined onClick={() => handleWidthChange(['wmGroProMargin']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'wmGroProMargin',
                width: 60,

                originalRender: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                render: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                ...getColumnRangeSearchProps('wmGroProMargin'),
                //filteredValue: filteredInfo.wmGroProMargin || null,


              },

            ]
          },
          {
            title: (
              <div className=" thbgc-seventh">
                プレミアム測算
                <CaretLeftOutlined onClick={() => handleWidthChange(['purPremium', 'pricePremium']
                  , [39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['purPremium', 'pricePremium']
                  , [60, 60])} />
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-seventh childrenHeight">
                    仕入プレミアム%
                    <CaretLeftOutlined onClick={() => handleWidthChange(['purPremium']
                      , [39])} />
                  </div>
                ),
                width: 60,
                dataIndex: 'purPremium',

                originalRender: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                render: (text) => {
                  if (text === null) {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ""}</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                ...getColumnRangeSearchProps('purPremium'),

                //filteredValue: filteredInfo.purPremium || null,

              },
              {
                title: (
                  <div className=" thbgc-seventh childrenHeight">
                    公式価格プレミアム%
                    <CaretLeftOutlined onClick={() => handleWidthChange(['pricePremium']
                      , [39])} />
                  </div>
                ),
                width: 60,
                dataIndex: 'pricePremium',

                originalRender: (text) => {
                  if (text === null) {
                    return <div className='textright'>0.00%</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                render: (text) => {
                  if (text === null) {
                    return <div className='textright'>0.00%</div>
                  } else {
                    return <div className='textright'>{text ? parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}%</div>
                  }
                },
                ...getColumnRangeSearchProps('pricePremium'),

                //filteredValue: filteredInfo.pricePremium || null,

              },
            ]
          },
          {
            title: (
              <div className=" thbgc-seventh">
                ブランド
                <CaretLeftOutlined onClick={() => handleWidthChange(['refPrice', 'issDate', 'kiYoShi']
                  , [39, 39, 39, 39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['refPrice', 'issDate', 'kiYoShi']
                  , [60, 60, 60, 60, 60])} />
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-seventh childrenHeight">
                    公式サイトURL
                    <CaretLeftOutlined onClick={() => handleWidthChange(['refUrl']
                      , [39])} />
                  </div>
                ),

                dataIndex: 'refUrl',
                // originalRender: (text) => text ? parseFloat(text).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 5 }) : "",
                // render: (text) => text ? parseFloat(text).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 5 }) : "",
                //filteredValue: filteredInfo.refUrl || null,

                key: 'refUrl',
                width: 60,
                // ...getColumnSearchProps('refUrl'),
                ...getColumnSearchProps('refUrl'),
                // ellipsis: {
                //   showTitle: false,
                // },
                originalRender: (text) => (
                  <Paragraph copyable={{
                    text: text,
                  }}>url</Paragraph>
                ),

                render: (text) => (
                  <Paragraph copyable={{
                    text: text,
                    tooltips: [text],
                  }}>url</Paragraph>
                )
              },
              {
                title: (
                  <div className=" thbgc-seventh childrenHeight">
                    公式価格JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['refPrice']
                      , [39])} />
                  </div>
                ),
                dataIndex: 'refPrice',

                //filteredValue: filteredInfo.refPrice || null,
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                width: 60,
                ...getColumnRangeSearchProps('refPrice'),

              },
              {
                title: (
                  <div className=" thbgc-seventh childrenHeight">
                    発行日
                    <CaretLeftOutlined onClick={() => handleWidthChange(['issDate']
                      , [39])} />
                  </div>
                ),
                width: 60,

                dataIndex: 'issDate',
                ...getColumnMonthSearchProps("issDate"),
                originalRender: (text) => <div className='textleft'>{text}</div>,
                render: (text) => <div className='textleft'>{text}</div>
                //filteredValue: filteredInfo.issDate || null,

              },
            ]
          },
          {
            title: (
              <div className=" thbgc-seventh">
                他社出品
                <CaretLeftOutlined onClick={() => handleWidthChange(['bicCamera', 'yoDoBaShi']
                  , [39, 39, 39, 39, 39])} />
                <CaretRightOutlined onClick={() => handleWidthreturn(['bicCamera', 'yoDoBaShi']
                  , [60, 60, 60, 60, 60])} />
              </div>
            ),
            children: [

              {
                title: (
                  <div className=" thbgc-seventh childrenHeight">
                    松本清JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['kiYoShi']
                      , [39])} />
                  </div>
                ),
                width: 60,
                dataIndex: 'kiYoShi',
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                ...getColumnRangeSearchProps('kiYoShi'),

                //filteredValue: filteredInfo.kiYoShi || null,

              },
              {
                title: (
                  <div className=" thbgc-seventh childrenHeight">
                    ビックカメラJPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['bicCamera']
                      , [39])} />
                  </div>
                ),
                width: 60,
                dataIndex: 'bicCamera',
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                ...getColumnRangeSearchProps('bicCamera'),

                //filteredValue: filteredInfo.bicCamera || null,

              },
              {
                title: (
                  <div className=" thbgc-seventh childrenHeight">
                    ヨドバシJPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['yoDoBaShi']
                      , [39])} />
                  </div>
                ),
                width: 60,
                dataIndex: 'yoDoBaShi',
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                ...getColumnRangeSearchProps('yoDoBaShi'),

                //filteredValue: filteredInfo.yoDoBaShi || null,

              },
            ]
          },


        ]
      },
      // 費用
      {
        title: (
          <div className=" thbgc-fifth">

            日本向販売
            <CaretLeftOutlined onClick={() => handleWidthChange(['shippingPriceJp'
            ], [39])} />
            <CaretRightOutlined onClick={() => handleWidthreturn(['shippingPriceJp'
            ]
              , [60])} />
          </div>
        ),

        // className: 'custom-header',
        children: [
          {
            title: (
              <div className=" thbgc-fifth">
                費用
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    測算物流割当JPY
                    <CaretLeftOutlined onClick={() => handleWidthChange(['shippingPriceJp']
                      , [39])} />
                  </div>
                ),

                dataIndex: 'shippingPriceJp',
                width: 60,

                //filteredValue: filteredInfo.proPrice || null,
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                ...getColumnRangeSearchProps('shippingPriceJp'),
              },
              {
                title: (
                  <div className=" thbgc-fifth childrenHeight">
                    総費用JPY
                  </div>
                ),
                //filteredValue: filteredInfo.proShipping || null,

                dataIndex: 'priceSumJp',
                width: 60,
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                ...getColumnRangeSearchProps('priceSumJp'),

              },
            ]
          },
          {
            title: (
              <div className=" thbgc-eigth">
                最小出品価格
              </div>
            ),
            children: [
              {
                title: (
                  <div className=" thbgc-eigth childrenHeight">
                    amaz onJPY
                  </div>
                ),
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                dataIndex: 'amazon',
                // key: 'amazon',
                width: 60,
                //filteredValue: filteredInfo.proPerson || null,
                ...getColumnRangeSearchProps('amazon'),


              },
              {
                title: (
                  <div className=" thbgc-eigth childrenHeight">
                    楽天JPY
                  </div>
                ),

                dataIndex: 'raKuTen',
                width: 60,
                //filteredValue: filteredInfo.proDate || null,
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                ...getColumnRangeSearchProps('raKuTen'),

              },
              {
                title: (
                  <div className=" thbgc-eigth childrenHeight">
                    Yaho oJPY
                  </div>
                ),
                //filteredValue: filteredInfo.madeDate || null,

                dataIndex: 'yaHoo',
                width: 60,
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                ...getColumnRangeSearchProps('yaHoo'),

              },
              {
                title: (
                  <div className=" thbgc-eigth childrenHeight">
                    Qoo1 0JPY
                  </div>
                ),
                //filteredValue: filteredInfo.validityDate || null,

                dataIndex: 'qooTen',
                width: 60,
                // 
                originalRender: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                render: (text) => <div className='textright'>{text ? parseFloat(text).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}</div>,
                ...getColumnRangeSearchProps('qooTen'),

              },
            ]
          },
        ]
      },
      {
        title: (
          <div className=" thbgc-ninth">
            操作</div>
        ),
        fixed: 'right',
        width: 70,
        render: (_, record) => {
          return (
            <Space size="middle">
              <Button icon={<EditTwoTone />} onClick={() => handleEdit(record)} />
              <Popconfirm
                title="削除"
                description="削除しますか？"
                onConfirm={() => onConfirm([record.id])}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  icon={<DeleteTwoTone />}
                />
              </Popconfirm>
            </Space>
          )
        }
      },
    ]
  );
  //3 レベルのメニューを取得した後、リセットパラメータを更新します
  useEffect(() => {
    const viewPowerListArray = (viewPowerList ? JSON.parse(viewPowerList) : null);
    const viewPowerobject = viewPowerListArray
      ? viewPowerListArray.reduce((obj, str) => {
        obj[str] = true;
        return obj;
      }, {})
      : {};

    async function getcategory() {
      console.log('关键', viewPowerobject)
      const generateFilters = (data) => {
        if (!data || !Array.isArray(data) || data.length === 0) {
          return null
        }

        return data.map(item => ({
          text: item.name,
          value: item.id,
          label: item.name,
          children: generateFilters(item.childList), // 注意这里使用 item.childList 作为参数
        }));
      };
      const filtertest = await request.get('/ocis/goods/goodsTree')
      // const filtertest = {
      //   "name": "一级",
      //   "childList": [
      //     {
      //       "name": "二级",
      //       "childList": [{ "name": "三级", }]
      //     },
      //     {
      //       "name": "小米110",
      //       "childList": []
      //     }
      //   ]
      // };

      const newfilter = generateFilters(filtertest.data)
      setEditcategory(newfilter)
      const newColumns = columns.map((group) => {
        if (group.children) {
          group.children = group.children.map((subgroup) => {
            if (subgroup.dataIndex === 'category') {
              return {
                ...subgroup,
                filters: newfilter,
              };
            } else {
              if (subgroup.children) {
                subgroup.children = subgroup.children.map((lastgrop) => {
                  if (lastgrop.dataIndex in viewPowerobject) {
                    return lastgrop;
                  } else {
                    return {
                      // ...lastgrop,
                      hidden: true,
                    };
                  }
                })
                let allEmpty = true;
                for (const obj of subgroup.children) {
                  if (obj.hidden !== true) {
                    // 如果对象不为空，则将 allEmpty 设置为 false，并退出循环
                    allEmpty = false;
                    break;
                  }
                }
                if (allEmpty) {
                  console.log('数组中的所有对象都为空');
                  return {
                    // ...lastgrop,
                    hidden: true,
                  };
                }
              }

            }


            return subgroup;
          });
          let iffirst = true;
          for (const objA of group.children) {
            if (objA.hidden !== true) {
              // 如果对象不为空，则将 allEmpty 设置为 false，并退出循环
              iffirst = false;
              break;
            }
          }
          if (iffirst) {
            console.log('数组中的所有对象都为空');
            return {
              // ...lastgrop,
              hidden: true,
            };
          }
        }






        return group;


      });
      console.log(newfilter);
      setColumns(newColumns);
    }

    if (!(Object.keys(viewPowerobject).length === 0)) {
      getcategory()
      console.log("Object.keys(viewPowerobject).length === 0", Object.keys(viewPowerobject).length === 0)
    }

  }, [viewPowerList]);

  // 列幅を変更するメソッド

  const handleWidthChange = (columnKeys, newWidths) => {
    // 寻找对应的列，并更新宽度
    const updatedColumns = columns.map((group) => {
      if (group.children) {
        group.children = group.children.map((subgroup) => {
          if (subgroup.children) {
            subgroup.children = subgroup.children.map((column) => {
              const index = columnKeys.indexOf(column.dataIndex);
              if (index !== -1) {
                // 找到需要更新宽度的列，返回更新后的列对象
                return {
                  ...column,
                  width: newWidths[index],
                  originalRender: column.render,
                  render: () => <div>...</div>
                };
              }
              return column;
            });
          }
          return subgroup;
        });
      }
      return group;
    });

    // 更新组件的列配置
    setColumns(updatedColumns);
  };
  const handleWidthreturn = (columnKeys, newWidths) => {
    // 寻找对应的列，并更新宽度
    const updatedColumns = columns.map((group) => {
      if (group.children) {
        group.children = group.children.map((subgroup) => {
          if (subgroup.children) {
            subgroup.children = subgroup.children.map((column) => {
              const index = columnKeys.indexOf(column.dataIndex);
              if (index !== -1) {
                // 找到需要更新宽度的列，返回更新后的列对象
                return {
                  ...column,
                  width: newWidths[index],
                  render: column.originalRender
                };
              }
              return column;
            });
          }
          return subgroup;
        });
      }
      return group;
    });

    // 更新组件的列配置
    setColumns(updatedColumns);
  };


  // 消去
  const onConfirm = async (data) => {

    console.log('点击删除', tableParams.pagination);
    // if (delPower) {
    const deleteIds = data;
    const result = await request.post('/ocis/goods/delGoodsByIds', deleteIds)
    console.log('删除调用后', tableParams.pagination);


    if (result.code === 200) {
      message.success(`削除しました ${result.data}`)
      setSelectedRowKeys([]);
    } else {
      message.error(`削除できなかった ${result.code}`)
    }
    // } else {
    //   message.error("権限がありません")
    // }
    setTableParams({
      ...currentPageRef.current,
      a: new Date().toLocaleString(),
    })
    // await delArticleAPI(data.id)
    // setReqData({
    //   ...reqData
    // })
  }
  //編集ボタンをクリック
  const handleEdit = (record) => {
    console.log('点击修改按钮', tableParams);
    setEditingKey(record.id);
    // 将日期字符串转换为 moment 对象
    const proDate = record.proDate ? moment(record.proDate, 'YYYY-MM-DD') : null;
    const madeDate = record.madeDate ? moment(record.madeDate, 'YYYY-MM') : null;
    const validityDate = record.validityDate ? moment(record.validityDate, 'YYYY-MM') : null;
    const issDate = record.issDate ? moment(record.issDate, 'YYYY-MM') : null;
    console.log({ proDate });
    form.setFieldsValue({
      ...record, proDate, madeDate, validityDate, issDate,
    });
    // 设置打开编辑表单里的上传图片内容为该条数据的图片地址
    setImageList([{ url: `${record.goodsImage}?t=${new Date().getTime()}` }]);

    setVisible(true);
  };
  useEffect(() => {
    if (editingKey[1] === 'copyadd') {
      handleOk();
    }
  }, [editingKey]);
  const copyAdd = () => {
    setEditingKey([editingKey, 'copyadd']);
  }
  //送信ボタンのアクションボリュームを変更します
  const handleOk = () => {
    console.log("xiugaizhixin", editingKey)
    form.validateFields().then(async (values) => {

      if (editingKey[1] === 'copyadd') {
        // コピーを新規追加
        // 送信するデータを構築します
        const updatedRecord = { ...data.find((record) => record.id === editingKey[0]), ...values };
        // ... 处理入参数据格式
        updatedRecord.fatherBrand = updatedRecord.fatherBrand ? JSON.stringify(updatedRecord.fatherBrand) : null;
        updatedRecord.sonBrand = updatedRecord.sonBrand ? JSON.stringify(updatedRecord.sonBrand) : null;
        updatedRecord.productName = updatedRecord.productName ? JSON.stringify(updatedRecord.productName) : null;
        updatedRecord.speName = updatedRecord.speName ? JSON.stringify(updatedRecord.speName) : null;
        updatedRecord.volume.volume = parseFloat((updatedRecord.volume.width * updatedRecord.volume.height * updatedRecord.volume.length).toFixed(1));
        updatedRecord.volume = updatedRecord.volume ? JSON.stringify(updatedRecord.volume) : null;
        updatedRecord.firstMenu = values.categoryMenu ? values.categoryMenu[0] : null;
        updatedRecord.secondMenu = values.categoryMenu ? values.categoryMenu[1] : null;
        updatedRecord.thirdMenu = values.categoryMenu ? values.categoryMenu[2] : null;
        updatedRecord.testGroMargin = updatedRecord.testGroMargin / 100;
        updatedRecord.wmIntMargin = updatedRecord.wmIntMargin / 100;
        updatedRecord.wmInvIntMargin = updatedRecord.wmInvIntMargin / 100;
        updatedRecord.appIntMargin = updatedRecord.appIntMargin / 100;


        updatedRecord.speContext = updatedRecord.speContext ? JSON.stringify(updatedRecord.speContext) : null;
        console.log(updatedRecord.proDate);

        updatedRecord.proDate = updatedRecord.proDate !== null && updatedRecord.proDate !== undefined ? updatedRecord.proDate.format("YYYY-MM-DD") : null;
        updatedRecord.madeDate = updatedRecord.madeDate !== null && updatedRecord.madeDate !== undefined ? updatedRecord.madeDate.format("YYYY-MM") : null;
        updatedRecord.issDate = updatedRecord.issDate !== null && updatedRecord.issDate !== undefined ? updatedRecord.issDate.format("YYYY-MM") : null;
        updatedRecord.validityDate = updatedRecord.validityDate !== null && updatedRecord.validityDate !== undefined ? updatedRecord.validityDate.format("YYYY-MM") : null;
        // updatedRecord.goodsImage = null;
        // 上传图片

        if (imageList && imageList[0] && imageList[0].response) {
          updatedRecord.goodsImage = imageList[0].response
        } else if (imageList.length <= 0) {
          updatedRecord.goodsImage = null
          // 没有值
        }


        const newdataResult = await request.post('/ocis/goods/addGoods', updatedRecord)
        if (newdataResult.code === 200) {
          message.success(`追加成功 ${newdataResult.data}`)
        } else {
          message.error(`追加失败 ${newdataResult.code}`)
        }
      }
      else if (editingKey) {
        // const updatedData = data.map((record) =>
        //   record.id === editingKey ? { ...record, ...values } : record
        // );
        // 送信するデータを構築します
        console.log('xiugai', values.categoryMenu)
        const updatedRecord = { ...data.find((record) => record.id === editingKey), ...values };
        // ...入力パラメータのデータ形式を処理します
        updatedRecord.fatherBrand = updatedRecord.fatherBrand ? JSON.stringify(updatedRecord.fatherBrand) : null;
        updatedRecord.sonBrand = updatedRecord.sonBrand ? JSON.stringify(updatedRecord.sonBrand) : null;
        updatedRecord.productName = updatedRecord.productName ? JSON.stringify(updatedRecord.productName) : null;
        updatedRecord.speName = updatedRecord.speName ? JSON.stringify(updatedRecord.speName) : null;
        updatedRecord.volume.volume = parseFloat((updatedRecord.volume.width * updatedRecord.volume.height * updatedRecord.volume.length).toFixed(1));
        updatedRecord.volume = updatedRecord.volume ? JSON.stringify(updatedRecord.volume) : null;
        updatedRecord.firstMenu = values.categoryMenu ? values.categoryMenu[0] : null;
        updatedRecord.secondMenu = values.categoryMenu ? values.categoryMenu[1] : null;
        updatedRecord.thirdMenu = values.categoryMenu ? values.categoryMenu[2] : null;
        updatedRecord.testGroMargin = updatedRecord.testGroMargin / 100;
        updatedRecord.wmIntMargin = updatedRecord.wmIntMargin / 100;
        updatedRecord.wmInvIntMargin = updatedRecord.wmInvIntMargin / 100;
        updatedRecord.appIntMargin = updatedRecord.appIntMargin / 100;


        updatedRecord.speContext = updatedRecord.speContext ? JSON.stringify(updatedRecord.speContext) : null;
        console.log(updatedRecord.proDate);

        updatedRecord.proDate = updatedRecord.proDate !== null && updatedRecord.proDate !== undefined ? updatedRecord.proDate.format("YYYY-MM-DD") : null;
        updatedRecord.madeDate = updatedRecord.madeDate !== null && updatedRecord.madeDate !== undefined ? updatedRecord.madeDate.format("YYYY-MM") : null;
        updatedRecord.issDate = updatedRecord.issDate !== null && updatedRecord.issDate !== undefined ? updatedRecord.issDate.format("YYYY-MM") : null;
        updatedRecord.validityDate = updatedRecord.validityDate !== null && updatedRecord.validityDate !== undefined ? updatedRecord.validityDate.format("YYYY-MM") : null;
        // updatedRecord.goodsImage = null;
        // 上传图片

        if (imageList && imageList[0] && imageList[0].response) {
          updatedRecord.goodsImage = imageList[0].response
        } else if (imageList.length <= 0) {
          updatedRecord.goodsImage = null
          // 没有值
        }





        const editResult = await request.post('/ocis/goods/updateGoods', updatedRecord)
        if (editResult.code === 200) {
          message.success(`修正しました ${editResult.data}`)
        } else {
          message.error(`修正できなかった ${editResult.code}`)
        }
      } else {
        // 添加新记录
        const updatedRecord = {
          ...values,
        };
        // ... 处理入参数据格式
        updatedRecord.fatherBrand = updatedRecord.fatherBrand ? JSON.stringify(updatedRecord.fatherBrand) : null;
        updatedRecord.sonBrand = updatedRecord.sonBrand ? JSON.stringify(updatedRecord.sonBrand) : null;
        updatedRecord.productName = updatedRecord.productName ? JSON.stringify(updatedRecord.productName) : null;
        updatedRecord.speName = updatedRecord.speName ? JSON.stringify(updatedRecord.speName) : null;
        updatedRecord.volume.volume = parseFloat((updatedRecord.volume.width * updatedRecord.volume.height * updatedRecord.volume.length).toFixed(1));
        updatedRecord.volume = updatedRecord.volume ? JSON.stringify(updatedRecord.volume) : null;

        updatedRecord.firstMenu = values.categoryMenu ? values.categoryMenu[0] : null;
        updatedRecord.secondMenu = values.categoryMenu ? values.categoryMenu[1] : null;
        updatedRecord.thirdMenu = values.categoryMenu ? values.categoryMenu[2] : null;

        updatedRecord.testGroMargin = updatedRecord.testGroMargin / 100;
        updatedRecord.wmIntMargin = updatedRecord.wmIntMargin / 100;
        updatedRecord.wmInvIntMargin = updatedRecord.wmInvIntMargin / 100;

        updatedRecord.speContext = updatedRecord.speContext ? JSON.stringify(updatedRecord.speContext) : null;

        updatedRecord.proDate = updatedRecord.proDate !== null && updatedRecord.proDate !== undefined ? updatedRecord.proDate.format("YYYY-MM-DD") : null;
        updatedRecord.madeDate = updatedRecord.madeDate !== null && updatedRecord.madeDate !== undefined ? updatedRecord.madeDate.format("YYYY-MM") : null;
        updatedRecord.issDate = updatedRecord.issDate !== null && updatedRecord.issDate !== undefined ? updatedRecord.issDate.format("YYYY-MM") : null;
        updatedRecord.validityDate = updatedRecord.validityDate !== null && updatedRecord.validityDate !== undefined ? updatedRecord.validityDate.format("YYYY-MM") : null;
        updatedRecord.goodsImage = imageList && imageList[0] && imageList[0].response !== null ? imageList[0].response : null;



        const newdataResult = await request.post('/ocis/goods/addGoods', updatedRecord)
        if (newdataResult.code === 200) {
          message.success(`追加成功 ${newdataResult.data}`)
        } else {
          message.error(`追加失败 ${newdataResult.code}`)
        }
      }
      // setData(updatedData);
      setVisible(false);
      setEditingKey('');
      console.log('点击上传按钮', tableParams);
      setTableParams({
        ...tableParams,
        a: new Date().toLocaleString(),
      })
      setImageList(null);

    });
  };

  const handleCancel = () => {
    setVisible(false);
    setEditingKey('');
    setImageList(null);
  };
  const generateFormItem = (disable, label, name, rules = [], widthfather = '430px', width, initialValue) => (
    <Form.Item label={<span style={{ width: '120px', textAlign: 'right', display: 'inline-block' }}>{label}</span>} name={name} rules={rules} initialValue={initialValue}
      style={{ width: widthfather, margin: '5px' }}>
      <Input disabled={!disable} style={{ width: width }} />
    </Form.Item>
  );
  const [imageList, setImageList] = useState([])
  const onUploadChange = (info) => {

    setImageList(info.fileList)

    console.log('gaibian', info);
  }
  const props = {
    name: 'file',
    action: 'https://m.oneslifejapan.co.jp/api/ocis/goods/importExcel', // 上传接口地址
    headers: {
      authorization: 'Bearer ' + userToken,
    },
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        // 从后端响应中提取 addList 数据
        const response = info.file.response;
        if (response && response.code === 200) {
          const { addList, editList } = response.data;

          setAddList({ 'add': addList, 'edit': editList }); // 设置 addList 状态
          setUploadKey(response.data.key);
          setModalVisible(true); // 显示确认弹窗
        } else {
          message.error('文件上传失败');
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} 文件上传失败`);
      }
    },
  };
  // アップロードを確認する
  const uploadhandleOk = async () => {

    console.log(uploadKey);
    const editResult = await request.get('/ocis/goods/addExcelData', {
      params: {
        cacheKey: uploadKey
      }
    })
    if (editResult.code === 200) {
      message.success(`成功 ${editResult.data}`);
      setTableParams({
        ...tableParams,
        a: new Date().toLocaleString(),
      })

    } else {
      message.error(`失败 ${editResult.code}`)
    }
    setModalVisible(false); // 关闭确认弹窗
  };
  const uploadhandleCancel = () => {
    setModalVisible(false); // 確認ポップアップウィンドウを閉じます
  };
  return (
    // component={false}如果包装了，是不是无法单独对行编辑，而是变成了对整个表格编辑
    <div >
      <Space
        style={{
          marginTop: 15,
          position: 'absolute',
          zIndex: 100
        }}
      >

        {/* <Button onClick={clearFilters}>Clear filters</Button> */}
        <Button type="primary" onClick={() => { setVisible(true); form.resetFields(); }}>
          追加
        </Button>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>アップロード</Button>
        </Upload>
        <Button onClick={excelExport}>ダウンロード</Button>
        <Button onClick={choosecancl}>取り消し</Button>

        <Popconfirm
          title="削除"
          description="削除しますか?"
          onConfirm={() => onConfirm(selectedRowKeys)}
          okText="Yes"
          cancelText="No"
        >
          <Button
          // type="primary"
          // danger
          // shape="circle"
          >削除</Button>

        </Popconfirm>

        <Button onClick={getUploadExcel}>Excelの例</Button>
        <Select
          defaultValue="全部言語"
          style={{
            width: 100,
          }}
          onChange={languageChange}
          options={[
            {
              value: 'all',
              label: '全部言語',
            },
            {
              value: 'chinese',
              label: '中文',
            },
            {
              value: 'japanese',
              label: '日本語',
            },
            {
              value: 'english',
              label: 'English',
            },
          ]}
        />
      </Space>


      <Table id='fontset'
        // components属性用于指定定制的渲染组件，这里指定了body（表格主体部分）中的cell要使用EditableCell组件。
        bordered

        columns={columns}
        dataSource={data}
        height={500}
        rowKey={(record) => record.id}
        scroll={{ y: windowHeight + 'px' }}
        // 设置多选
        rowSelection={{
          fixed: 'left',
          // type: 'checkbox',
          columnWidth: 25,
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            console.log(selectedRowKeys);
            setSelectedRowKeys(selectedRowKeys);
          },
          preserveSelectedRowKeys: true,
        }}

        pagination={tableParams.pagination}


        loading={loading}
        onChange={handleChange}
        // 设置奇偶行不通颜色
        rowClassName={
          (_, index) => index % 2 === 0 ? 'editable-row odd' : 'editable-row even'
        }
      />

      <Modal
        title="Edit Form"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1400}
        id="custom-form"

      >
        {editingKey && <Button onClick={copyAdd}>この上で追加</Button>}
        <Form form={form}
          layout="inline"


        >
          <div style={{}}>
            {/* {generateFormItem(editPowerobject.janCode, 'JAN Code', 'janCode', [{ required: true }])} */}
            <Form.Item label={<span style={{ display: 'inline-block' }}>JAN Code</span>} name={['janCode']} style={{ marginLeft: '61px', marginTop: '5px' }} rules={[{ required: true }]}>
              <Input style={{ width: '284px', }} disabled={1 && !editPowerobject.janCode} />
            </Form.Item>
            <Form.Item
              name="inStockMethod"
              label="在庫"
              style={{ marginLeft: '100px', marginTop: '5px' }}
            >
              <Select
                placeholder="Select a option"
                disabled={1 && !editPowerobject.inStockMethod}
                style={{ marginLeft: '0px', width: '288px' }}
              >
                <Option value={1}>大量</Option>
                <Option value={2}>少量</Option>
                <Option value={3}>無し</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="saleStatus"
              label="出品"
              style={{ marginLeft: '100px', marginTop: '5px' }}
            >
              <Select
                placeholder="Select a option"
                disabled={1 && !editPowerobject.saleStatus}
                style={{ width: '110px', marginLeft: '0px' }}
              >
                <Option value={1}>出品中</Option>
                <Option value={2}>未出品</Option>
                <Option value={3}>中止</Option>
                <Option value={4}>指名買う</Option>
              </Select>
            </Form.Item>
          </div>
          <div style={{}}>
            {generateFormItem(editPowerobject.fatherBrand, '主ブランドJP', ['fatherBrand', 'jp'], null)}
            {generateFormItem(editPowerobject.fatherBrand, '主ブランドCN', ['fatherBrand', 'cn'], null)}
            {generateFormItem(editPowerobject.fatherBrand, '主ブランドEN', ['fatherBrand', 'en'], null)}
          </div>
          <div style={{}}>
            {generateFormItem(editPowerobject.sonBrand, '従ブランドJP', ['sonBrand', 'jp'], null)}
            {generateFormItem(editPowerobject.sonBrand, '従ブランドCN', ['sonBrand', 'cn'], null)}
            {generateFormItem(editPowerobject.sonBrand, '従ブランドEN', ['sonBrand', 'en'], null)}
          </div>
          <div style={{}}>
            {generateFormItem(editPowerobject.productName, '商品名JP', ['productName', 'jp'], null)}
            {generateFormItem(editPowerobject.productName, '商品名CN', ['productName', 'cn'], null)}
            {generateFormItem(editPowerobject.productName, '商品名EN', ['productName', 'en'], null)}
          </div>
          <div style={{}}>
            {generateFormItem(editPowerobject.speName, '仕様JP', ['speName', 'jp'], null)}
            {generateFormItem(editPowerobject.speName, '仕様CN', ['speName', 'cn'], null)}
            {generateFormItem(editPowerobject.speName, '仕様EN', ['speName', 'en'], null)}
          </div>
          <div style={{}}>
            {generateFormItem(editPowerobject.speContext, '内容量JP', ['speContext', 'jp'], null)}
            {generateFormItem(editPowerobject.speContext, '内容量CN', ['speContext', 'cn'], null)}
            {generateFormItem(editPowerobject.speContext, '内容量EN', ['speContext', 'en'], null)}
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item label={<span style={{ width: '125px', display: 'inline-block' }}>長さCM</span>} name={['volume', 'length']} style={{ marginTop: '5px' }} >
              <Input style={{ width: '110px', }} disabled={1 && !editPowerobject.volume} />
            </Form.Item>
            <Form.Item label={<span style={{ width: '30px', display: 'inline-block' }}>幅CM</span>} name={['volume', 'width']} style={{ marginLeft: '22px', marginTop: '5px' }}     >
              <Input style={{ width: '107px' }} disabled={1 && !editPowerobject.volume} />
            </Form.Item>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item label={<span style={{ width: '114px', display: 'inline-block' }}>高さCM</span>} name={['volume', 'height']} style={{ marginTop: '5px' }}     >
                <Input style={{ width: '110px' }} disabled={1 && !editPowerobject.volume} />
              </Form.Item>
              <Form.Item label={<span style={{ width: '48px', display: 'inline-block' }}>重さKG</span>} name='weight' style={{ marginLeft: '12px', marginTop: '5px' }}     >
                <Input style={{ width: '98px', marginRight: '0px' }} disabled={1 && !editPowerobject.weight} />
              </Form.Item>
            </div>
          </div>
          {/* 三级菜单 */}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {generateFormItem(editPowerobject.proPrice, '仕入価格JPY', 'proPrice', null, '243px', '110px')}
            {generateFormItem(editPowerobject.proShipping, '仕入送料JPY', 'proShipping', null, '177px', '90px')}
            {/* {generateFormItem(editPowerobject.proPerson, '仕入者', 'proPerson', null)} */}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item label={<span style={{ width: '120px', display: 'inline-block' }}>仕入者</span>} name="proPerson" style={{ margin: '5px' }}>
                <Select placeholder="Select province" disabled={1 && !editPowerobject.proPerson} style={{ width: '100px' }}>
                  <Option value="大貫">大貫</Option>
                  <Option value="麗沢">麗沢</Option>
                  <Option value="麗沢">その他</Option>
                </Select>
              </Form.Item>
              <Form.Item label={<span style={{ width: '65px', display: 'inline-block' }}>仕入時間</span>} name="proDate" style={{ marginTop: '5px' }}>
                <DatePicker format="YYYY-MM-DD" disabled={1 && !editPowerobject.proDate} style={{ width: '115px' }} />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item label={<span style={{ width: '115px', display: 'inline-block' }}>製造日</span>} name="madeDate" style={{ marginTop: '5px' }}>
                <DatePicker format="YYYY-MM" picker="month" disabled={1 && !editPowerobject.madeDate} style={{ width: '100px' }} />
              </Form.Item>
              <Form.Item label={<span style={{ width: '70px', display: 'inline-block' }}>有効期限切れ</span>} name="validityDate" style={{ marginTop: '5px' }}>
                <DatePicker format="YYYY-MM" picker="month" disabled={1 && !editPowerobject.validityDate} style={{ width: '100px' }} />
              </Form.Item>
            </div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>

            {generateFormItem(editPowerobject.taxExcPrice, '▲税別価格JPY', 'taxExcPrice', null, '213px', '80px')} <span style={{ marginTop: '10px', marginRight: '30px' }}></span>
            {generateFormItem(editPowerobject.staPrice, '▲税込価格JPY', 'staPrice', null, '177px', '80px')}<span style={{ marginTop: '10px', marginRight: '30px' }}></span>

            <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '0px' }}>
              {generateFormItem(editPowerobject.appIntMargin, '▲APP小売利益率%', 'appIntMargin', null, '172px', '70px')}<span style={{ marginTop: '10px', marginRight: '0px' }}>%</span>
              {generateFormItem(editPowerobject.appInterest, '▲APP内販売者の利益JPY', 'appInterest', null, '210px', '75px')}
            </div>
          </div>


          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {generateFormItem(editPowerobject.testGroMargin, '測算粗利益率', 'testGroMargin', null, '213px', '80px')} <span style={{ marginTop: '10px', marginRight: '0px' }}>%</span>
            {generateFormItem(editPowerobject.wmIntMargin, 'WC微客top利益率', 'wmIntMargin', null, '195px', '80px')}<span style={{ marginTop: '10px', marginRight: '0px' }}>%</span>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '0px' }}>
              {generateFormItem(editPowerobject.wmInvIntMargin, 'WC邀請人top利益率', 'wmInvIntMargin', null, '192px', '70px')}<span style={{ marginTop: '10px', marginRight: '0px' }}>%</span>
              {generateFormItem(editPowerobject.empPrice, 'ガイド持帰価格JPY', 'empPrice', null, '200px', '75px')}
            </div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '0px' }}>
            <Form.Item name="refUrl" label={<span style={{ width: '120px', display: 'inline-block' }}>公式サイトURL</span>} style={{ width: '430px', margin: '5px' }}
              rules={[
                {
                  type: 'url',
                  warningOnly: true,
                },
                {
                  type: 'string',
                  min: 6,
                },
              ]}
            >
              <Input placeholder="input placeholder" disabled={1 && !editPowerobject.refUrl} />
            </Form.Item>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {generateFormItem(editPowerobject.refPrice, '公式価格JPY', 'refPrice', null, '230px', '70px')}
              <Form.Item label={<span style={{ width: '70px', display: 'inline-block' }}>発行日</span>} name="issDate" style={{ marginTop: '5px', marginRight: '0px' }}>
                <DatePicker format="YYYY-MM" picker="month" disabled={1 && !editPowerobject.issDate} style={{ width: '115px' }} />
              </Form.Item>
            </div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {generateFormItem(editPowerobject.kiYoShi, '松本清JPY', 'kiYoShi', null, '223px', '90px')}
            {generateFormItem(editPowerobject.bicCamera, 'ビックカメラJPY', 'bicCamera', null, '200px', '90px')}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {generateFormItem(editPowerobject.yoDoBaShi, 'ヨドバシJPY', 'yoDoBaShi', null, '230px', '70px')}
              <Form.Item label={<span style={{ width: '60px', display: 'inline-block' }}>カテゴリ</span>} name='categoryMenu' style={{ marginTop: '5px', marginRight: '0px' }}>
                <Cascader
                  options={editcategory}
                  disabled={1 && !editPowerobject.firstMenu}
                />
              </Form.Item>
            </div>
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item label={<span style={{ width: '125px', display: 'inline-block' }}>画像</span>} name='goodsImage'>
              <Upload

                name="file"
                listType="picture-card"
                showUploadList
                action={'https://m.oneslifejapan.co.jp/api/ocis/goods/upload'}
                onChange={onUploadChange}
                maxCount={1}
                fileList={imageList}
                disabled={1 && !editPowerobject.goodsImage}
              >
                <div style={{ marginTop: 8 }}>
                  <PlusOutlined
                  />
                </div>
              </Upload>
            </Form.Item>
            <Form.Item label={<span style={{ width: '195px', display: 'inline-block' }}>セット内容</span>} name='setContent' rules={null}>
              <TextArea rows={4} disabled={1 && !editPowerobject.setContent} />
            </Form.Item>
          </div>



          {/* 从 fatherBrand 对象中获取的字段 */}
        </Form >
      </Modal >
      <Modal
        title="确定上传吗"
        open={modalVisible}
        onOk={uploadhandleOk}
        onCancel={uploadhandleCancel}
      >
        <p>添加 {addList.add} 条记录</p>
        <p>修改 {addList.edit} 条记录</p>
      </Modal>
    </div >
  );
};
export default Home;

