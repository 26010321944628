import React, { useState, useEffect } from 'react';
import { DownOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Tree, Space, Popconfirm, message, Input, Button } from 'antd';
import { request } from '../../utils';
import './index.scss';

const fieldNames = {
  title: 'name',
  key: 'id',
  children: 'children',
  level: 'level',
  parentId: 'parentId'
};

const Publish = (props) => {
  const [treeData, setTreeData] = useState([]);
  const [editingNode, setEditingNode] = useState(null);

  useEffect(() => {
    async function getcategory() {
      const generateFilters = (data) => {
        if (!data || !Array.isArray(data) || data.length === 0) {
          return null;
        }
        return data.map(item => ({
          id: item.id,
          name: item.name,
          parentId: item.parentId,
          level: item.level,
          children: generateFilters(item.childList),
        }));
      };
      const filtertest = await request.get('/ocis/goods/goodsTree');
      const newfilter = generateFilters(filtertest.data);
      setTreeData(newfilter);
    }
    getcategory();
  }, []);

  const handleAddChild = (parentId, level, nodes = treeData) => {
    const updatedNodes = nodes.map(node => {
      if (node.id === parentId) {
        const newNode = {
          id: Date.now(), // 假设这是唯一的 ID
          name: '新节点',
          parentId: parentId,
          level: level + 1
        };
        const updatedNode = { ...node };
        if (!updatedNode.children) {
          updatedNode.children = [];
        }
        updatedNode.children.push(newNode);
        return updatedNode;
      } else if (node.children) {
        return { ...node, children: handleAddChild(parentId, level, node.children) };
      }
      return node;
    });
    return updatedNodes;
  };


  const sonDeleteNode = (nodeId, nodes = treeData) => {
    const updatedNodes = nodes.filter(node => {
      if (node.id === nodeId) {
        return false;
      } else if (node.children) {
        node.children = sonDeleteNode(nodeId, node.children);
      }
      return true;
    });
    return updatedNodes;
  };


  const handleDeleteNode = async (nodeId) => {
    try {
      // 发送删除节点的请求，使用 GET 请求，并将 nodeId 作为查询参数
      await request.get(`/ocis/goods/delGoodsTreeById?nodeId=${nodeId}`);

      // 删除成功后，更新本地状态
      const updatedNodes = sonDeleteNode(nodeId, treeData);
      setTreeData(updatedNodes);

      // 给出成功提示
      message.success('削除しました');
    } catch (error) {
      // 请求错误处理
      console.error('删除节点请求错误:', error);
      message.error('削除できなかった');
      throw error; // 可以选择抛出异常，让调用方处理
    }
  };

  const handleDeleteNodeClick = async (nodeId, newName) => {
    if (newName === "新节点") {
      // 如果节点名称为 "新节点"，直接从树形结构中删除节点并更新状态
      const updatedNodes = treeData.filter(node => {
        if (node.id === nodeId) {
          return false; // 如果节点 ID 匹配，则不包含该节点
        } else if (node.children) {
          node.children = sonDeleteNode(nodeId, node.children); // 递归处理子节点
        }
        return true; // 其他情况保留节点
      });

      // 更新本地状态
      setTreeData(updatedNodes);
      return; // 结束函数执行
    }

    try {
      // 等待handleDeleteNode函数完成异步操作
      await handleDeleteNode(nodeId);
      // 执行到这里说明删除成功

      // 无需在此处更新状态，因为handleDeleteNode函数中已经更新了状态
    } catch (error) {
      // 处理错误情况
      console.error('删除节点请求错误:', error);
      message.error('削除できなかった');
    }
  };



  const handleEditNode = (nodeId, newName, level, parentId, nodes = treeData) => {
    return new Promise((resolve, reject) => {
      let updatedNodes = [];

      const updateNode = (node) => {
        if (node.id === nodeId) {
          const updatedNode = { ...node, id: nodeId, name: newName, level: level, parentId: parentId };
          updatedNodes.push(updatedNode);
          return updatedNode;
        } else if (node.children) {
          return { ...node, children: node.children.map(updateNode) };
        }
        return node;
      };

      updatedNodes = nodes.map(updateNode);

      // 创建 targetNode 对象
      const targetNode = { id: nodeId, name: newName, level: level, parentId: parentId };

      // 发送更新节点的请求
      request.post('/ocis/goods/updateGoodsTree', targetNode)
        .then(response => {
          if (response.code === 200) {

            message.success('編集成功');
            resolve(updatedNodes); // 成功时调用 resolve，并传递更新后的节点列表

          } else if (response.code === 1003) {
            message.error(`データはすでに存在します ${response.message}`);
            reject(response.message); // 失败时调用 reject
          }
          else {
            message.error(`操作できなかった ${response.message}`);
            reject(response.message); // 失败时调用 reject
          }



        })
        .catch(error => {
          console.error('接口调用失败:', error);
          message.error('編集できなかった');
          reject(error); // 失败时调用 reject
        });
    });
  };

  const handleEditNodeClick = (nodeId, newName, level, parentId) => {

    if (newName === "新节点") {
      return;
    }

    // 调用 handleEditNode 函数，并处理 Promise 结果
    handleEditNode(nodeId, newName, level, parentId)
      .then(updatedTreeData => {
        setTreeData(updatedTreeData);
        console.log("333333333333333", updatedTreeData);
        setEditingNode(null);
      })
      .catch(error => {
        console.error("操作失败:", error);
      });
  };


  const handleAddTopLevelNode = () => {
    // 逻辑来添加顶级节点
    // 例如：
    const newTopLevelNode = {
      id: Date.now(), // 假设这是唯一的 ID
      name: '新节点',
      level: 1, // 顶级节点的级别为 1
      parentId: null, // 顶级节点的父级 ID 为 null
    };

    // 更新树数据
    setTreeData([...treeData, newTopLevelNode]);
  };

  const handleAddChildClick = (parentId, level) => {
    const updatedTreeData = handleAddChild(parentId, level);
    setTreeData(updatedTreeData);
  };

  return (

    <div>
      <div style={{ marginBottom: '20px' }}>
        <Button style={{ fontSize: '12px', padding: '6px 12px', background: '#f0f0f0', border: '1px solid #d9d9d9', color: 'green' }} onClick={handleAddTopLevelNode}>一級分類の追加

        </Button>
      </div>


      <Tree
        showLine
        defaultExpandAll={true}
        defaultExpandParent={true}
        switcherIcon={<DownOutlined />}
        treeData={treeData}
        fieldNames={fieldNames}
        titleRender={(nodeData = {}) => {
          return (
            <div style={{ width: '200px' }} className='treeTitle'>
              {editingNode === nodeData[fieldNames.key] ? (
                <Input
                  defaultValue={nodeData[fieldNames.title]}
                  onPressEnter={(e) => handleEditNodeClick(nodeData[fieldNames.key], e.target.value, nodeData[fieldNames.level], nodeData[fieldNames.parentId])}
                  onBlur={(e) => handleEditNodeClick(nodeData[fieldNames.key], e.target.value, nodeData[fieldNames.level], nodeData[fieldNames.parentId])}
                />
              ) : (
                <>
                  <span>{nodeData[fieldNames.title]}</span>
                  <Space size={4} className='treeBtn'>
                    <EditOutlined
                      style={{ color: 'blue', cursor: 'pointer' }}
                      onClick={() => setEditingNode(nodeData[fieldNames.key])}
                    />
                    <Popconfirm
                      title="削除"
                      description="削除しますか?"
                      onConfirm={() => handleDeleteNodeClick(nodeData[fieldNames.key], nodeData[fieldNames.title])}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined
                        style={{ color: 'red', cursor: 'pointer' }}
                      />
                    </Popconfirm>

                    {nodeData[fieldNames.level] < 3 && ( // 检查节点级别是否小于3
                      <PlusOutlined
                        style={{ color: 'green', cursor: 'pointer' }}
                        onClick={() => handleAddChildClick(nodeData[fieldNames.key], nodeData[fieldNames.level])}
                      />
                    )}
                  </Space>
                </>
              )}
            </div>
          );
        }}
      />

    </div>



  );
};

export default Publish;
