import React, { useState, useEffect } from 'react';
import { Table, Switch, Checkbox, Select, Button, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { request } from '../../utils';
import './index.scss'
const { Option } = Select;
const Article = () => {
  // 关联多选框的状态
  const [checkboxStates, setCheckboxStates] = useState({
    '1': { viewPowerList: true, editPowerList: true },
  });

  const [options, setOptions] = useState([
  ]);
  const [flash, setFlash] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [delchecked, setDelchecked] = useState(true);
  const [disablechecked, setDisablechecked] = useState(true);
  // 获取用户列表
  useEffect(() => {
    async function getcategory() {
      const userdata = await request.get('/ocis/user/listAll')
      const processeduserdata = userdata.map(item => {
        return {
          ...item,

          editPowerList: (item.editPowerList ? JSON.parse(item.editPowerList) : null),
          viewPowerList: (item.viewPowerList ? JSON.parse(item.viewPowerList) : null),
          // delePowerList: (item.delePowerList ? JSON.parse(item.delePowerList) : null),

        };
      });

      setOptions(processeduserdata)
    }
    getcategory()
  }, [flash]);

  // 切换用户调用函数
  const handleSelectChange = (value) => {

    const selectedOption = options.find(option => option.name === value);
    setSelectedUser(selectedOption);
    console.log(selectedOption);

    // 选择用户后读出删除权限显示在页面
    setDelchecked(selectedOption.delPower)

    // 选择用户后读出禁用权限显示在页面
    setDisablechecked(selectedOption.disable)

    // 解析原始数据
    const parsedData = selectedOption;

    // 生成新对象
    const newObject = {};

    // 处理 viewPowerList
    parsedData.viewPowerList.forEach(item => {
      newObject[item] = {
        viewPowerList: true,
        editPowerList: false
      };
    });

    // 处理 editPowerList
    parsedData.editPowerList.forEach(item => {
      if (!newObject[item]) {
        newObject[item] = {
          viewPowerList: false,
          editPowerList: true
        };
      } else {
        newObject[item].editPowerList = true;
      }
    });
    setCheckboxStates(newObject);
    // 输出新对象
    console.log(newObject);

  };
  useEffect(() => {
    console.log(checkboxStates);
  }, [checkboxStates]);

  const dataSource = [
    {
      key: '1',
      firstmenu: '出品',
      backgroundColor: '#CCFFCC',
      parent: null,
      children: [
        {
          key: '1-1',
          secondmenu: '出品',
          parent: '1',
          backgroundColor: '#CCFFCC',
          children: [
            {
              backgroundColor: '#CCFFCC',
              parent: '1-1',
              key: 'goodsImage',
              thirdmenu: '縮図',
            },
            {
              backgroundColor: '#CCFFCC',
              parent: '1-1',
              key: 'janCode',
              thirdmenu: 'JAN Code',
            },
          ],
        },
        {
          key: '1-2',
          parent: '1',
          backgroundColor: '#CCFFCC',
          secondmenu: '出品',
          children: [
            {
              backgroundColor: '#CCFFCC',
              key: 'inStockMethod',
              thirdmenu: '在庫状況',
              parent: '1-2',
            },
            {
              backgroundColor: '#CCFFCC',
              key: 'saleStatus',
              thirdmenu: '出品状況',
              parent: '1-2',
            },
          ],
        },
      ],
    },
    {
      key: '2',
      firstmenu: '商品',
      backgroundColor: '#CCFFCC', // Replaced with '#CCFFCC'
      parent: null,
      children: [
        {
          key: '2-1',
          parent: '2',
          secondmenu: '商品',
          backgroundColor: '#CCFFCC', // Replaced with '#CCFFCC'
          children: [
            {
              parent: '2-1',
              key: 'fatherBrand',
              thirdmenu: '主ブランド',
              backgroundColor: '#CCFFCC', // Replaced with '#CCFFCC'
            },
            {
              parent: '2-1',
              key: 'sonBrand',
              thirdmenu: '従ブランド',
              backgroundColor: '#CCFFCC', // Replaced with '#CCFFCC'
            },
            {
              parent: '2-1',
              key: 'productName',
              thirdmenu: '商品名',
              backgroundColor: '#CCFFCC', // Replaced with '#CCFFCC'
            },
            {
              parent: '2-1',
              key: 'speName',
              thirdmenu: '仕様',
              backgroundColor: '#CCFFCC', // Replaced with '#CCFFCC'
            },
            {
              parent: '2-1',
              key: 'speContext',
              thirdmenu: '内容量',
              backgroundColor: '#CCFFCC', // Replaced with '#CCFFCC'
            },
          ],
        }

      ],
    },
    {
      key: '3',
      firstmenu: '商品詳細',
      backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
      parent: null,
      children: [
        {
          key: '3-1',
          parent: '3',
          secondmenu: 'セット',
          backgroundColor: '#FFF2CC', // Replaced with '#CCFFCC'
          children: [
            {
              parent: '3-1',
              key: 'set',
              thirdmenu: 'セット記号',
              backgroundColor: '#FFF2CC', // Replaced with '#CCFFCC'
            },
            {
              parent: '3-1',
              key: 'setContent',
              thirdmenu: 'セット内容',
              backgroundColor: '#FFF2CC', // Replaced with '#CCFFCC'
            },
          ],
        },
        {
          key: '3-2',
          parent: '3',
          secondmenu: '重量',
          backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
          children: [
            {
              key: 'volume',
              parent: '3-2',
              thirdmenu: 'サイズcm',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'volWeight',
              parent: '3-2',
              thirdmenu: '体積重量kg',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'weight',
              parent: '3-2',
              thirdmenu: '正味重量kg',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'lightItems',
              parent: '3-2',
              thirdmenu: '軽い品物',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
          ],
        },
        {
          key: '3-3',
          parent: '3',
          secondmenu: 'カテゴリ',
          backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
          children: [
            {
              key: 'firstMenu',
              parent: '3-3',
              thirdmenu: 'カテゴリⅠ',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'secondMenu',
              parent: '3-3',
              thirdmenu: 'カテゴリⅡ',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'thirdMenu',
              parent: '3-3',
              thirdmenu: 'カテゴリⅢ',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
          ],
        },
      ],
    },
    {
      key: '4',
      firstmenu: '仕入',
      backgroundColor: '#CCFFFF', // Replaced with '#CCFFFF'
      parent: null,
      children: [
        {
          key: '4-1',
          parent: '4',
          secondmenu: '仕入費用',
          backgroundColor: '#CCFFFF', // Replaced with '#CCFFFF'
          children: [
            {
              key: 'proPrice',
              parent: '4-1',
              thirdmenu: '仕入価格JPY',
              backgroundColor: '#CCFFFF', // Replaced with '#CCFFFF'
            },
            {
              key: 'proShipping',
              parent: '4-1',
              thirdmenu: '仕入送料JPY',
              backgroundColor: '#CCFFFF', // Replaced with '#CCFFFF'
            },
            {
              key: 'proSum',
              parent: '4-1',
              thirdmenu: '仕入累計JPY',
              backgroundColor: '#CCFFFF', // Replaced with '#CCFFFF'
            },
          ],
        },
        {
          key: '4-2',
          parent: '4',
          secondmenu: '仕入情報',
          backgroundColor: '#CCFFFF', // Replaced with '#CCFFFF'
          children: [
            {
              key: 'proPerson',
              parent: '4-2',
              thirdmenu: '仕入者',
              backgroundColor: '#CCFFFF', // Replaced with '#CCFFFF'
            },
            {
              key: 'proDate',
              parent: '4-2',
              thirdmenu: '仕入時間',
              backgroundColor: '#CCFFFF', // Replaced with '#CCFFFF'
            },
            {
              key: 'madeDate',
              parent: '4-2',
              thirdmenu: '製造日',
              backgroundColor: '#CCFFFF', // Replaced with '#CCFFFF'
            },
            {
              key: 'validityDate',
              parent: '4-2',
              thirdmenu: '有効期限切れ',
              backgroundColor: '#CCFFFF', // Replaced with '#CCFFFF'
            },
          ],
        },
      ],
    },
    {
      key: '5',
      firstmenu: '中国向販売',
      backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
      parent: null,
      children: [
        {
          key: '5-1',
          parent: '5',
          secondmenu: '物流',
          backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
          children: [
            {
              key: 'shippingPriceCn',
              parent: '5-1',
              thirdmenu: '測算物流割当ⒸJPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'updateShiPrice',
              parent: '5-1',
              thirdmenu: '修正物流割当ⒸJPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'testPrice',
              parent: '5-1',
              thirdmenu: '測算物流費用率Ⓒ%',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
          ],
        },
        {
          key: '5-2',
          parent: '5',
          secondmenu: '費用',
          backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
          children: [
            {
              key: 'priceSumCn',
              parent: '5-2',
              thirdmenu: '総費用ⒸJPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
          ],
        },
        {
          key: '5-3',
          parent: '5',
          secondmenu: '価格測算',
          backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
          children: [
            {
              key: 'testGroMargin',
              parent: '5-3',
              thirdmenu: '測算粗利益率Ⓒ%',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'estPrice',
              parent: '5-3',
              thirdmenu: '測算価格ⒸJPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
          ],
        },
        {
          key: '5-4',
          parent: '5',
          secondmenu: '標準価格',
          backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
          children: [
            {
              key: 'taxExcPrice',
              parent: '5-4',
              thirdmenu: '税別価格JPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'staPrice',
              parent: '5-4',
              thirdmenu: '税込価格JPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'groMargin',
              parent: '5-4',
              thirdmenu: '粗利益率%',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
          ],
        },
        {
          key: '5-5',
          parent: '5',
          secondmenu: '流通価格',
          backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
          children: [
            {
              key: 'appIntMargin',
              parent: '5-5',
              thirdmenu: 'APP小売利益率%',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'appInterest',
              parent: '5-5',
              thirdmenu: 'APP内販売者の利益JPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'wmIntMargin',
              parent: '5-5',
              thirdmenu: 'WC微客top利益率%',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'wmInterest',
              parent: '5-5',
              thirdmenu: 'WC微客top利益JPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'wmInvIntMargin',
              parent: '5-5',
              thirdmenu: 'WC邀請人top利益率%',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'wmInvInterest',
              parent: '5-5',
              thirdmenu: 'WC邀請人top利益JPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'agentPrice',
              parent: '5-5',
              thirdmenu: '販売代理の決済価格JPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
            {
              key: 'empPrice',
              parent: '5-5',
              thirdmenu: 'ガイドさん持帰価格JPY',
              backgroundColor: '#FFF2CC', // Replaced with '#FFF2CC'
            },
          ],
        },
      ],
    },
    {
      key: '6',
      firstmenu: '参考情報',
      backgroundColor: '#b37feb', // Replaced with '#b37feb'
      parent: null,
      children: [
        {
          key: '6-1',
          parent: '6',
          secondmenu: '販促測算',
          backgroundColor: '#b37feb', // Replaced with '#b37feb'
          children: [
            {
              key: 'appGroProMargin',
              parent: '6-1',
              thirdmenu: 'APP利用可能粗利益率%',
              backgroundColor: '#b37feb', // Replaced with '#b37feb'
            },
            {
              key: 'wmGroProMargin',
              parent: '6-1',
              thirdmenu: 'WM利用可能粗利益率%',
              backgroundColor: '#b37feb', // Replaced with '#b37feb'
            },
          ],
        },
        {
          key: '6-2',
          parent: '6',
          secondmenu: 'プレミアム測算',
          backgroundColor: '#b37feb', // Replaced with '#b37feb'
          children: [
            {
              key: 'purPremium',
              parent: '6-2',
              thirdmenu: '仕入プレミアム%',
              backgroundColor: '#b37feb', // Replaced with '#b37feb'
            },
            {
              key: 'pricePremium',
              parent: '6-2',
              thirdmenu: '公式価格プレミアム%',
              backgroundColor: '#b37feb', // Replaced with '#b37feb'
            },
          ],
        },
        {
          key: '6-3',
          parent: '6',
          secondmenu: 'ブランド',
          backgroundColor: '#b37feb', // Replaced with '#b37feb'
          children: [
            {
              key: 'refUrl',
              parent: '6-3',
              thirdmenu: '公式サイトURL',
              backgroundColor: '#b37feb', // Replaced with '#b37feb'
            },
            {
              key: 'refPrice',
              parent: '6-3',
              thirdmenu: '公式価格JPY',
              backgroundColor: '#b37feb', // Replaced with '#b37feb'
            },
            {
              key: 'issDate',
              parent: '6-3',
              thirdmenu: '発行日',
              backgroundColor: '#b37feb', // Replaced with '#b37feb'
            },
          ],
        },
        {
          key: '6-4',
          parent: '6',
          secondmenu: '他社出品',
          backgroundColor: '#b37feb', // Replaced with '#b37feb'
          children: [
            {
              key: 'kiYoShi',
              parent: '6-4',
              thirdmenu: '松本清JPY',
              backgroundColor: '#b37feb', // Replaced with '#b37feb'
            },
            {
              key: 'bicCamera',
              parent: '6-4',
              thirdmenu: 'ビックカメラJPY',
              backgroundColor: '#b37feb', // Replaced with '#b37feb'
            },
            {
              key: 'yoDoBaShi',
              parent: '6-4',
              thirdmenu: 'ヨドバシJPY',
              backgroundColor: '#b37feb', // Replaced with '#b37feb'
            },
          ],
        },
      ],
    },
    {
      "key": "7",
      "firstmenu": "日本向販売",
      "backgroundColor": "#ffd666", // Replaced with '#ffd666'
      "parent": null,
      "children": [
        {
          "key": "7-1",
          "parent": "7",
          "secondmenu": "費用",
          "backgroundColor": "#ffd666", // Replaced with '#ffd666'
          "children": [
            {
              "key": "shippingPriceJp",
              "parent": "7-1",
              "thirdmenu": "測算物流割当ⒿJPY",
              "backgroundColor": "#ffd666" // Replaced with '#ffd666'
            },
            {
              "key": "priceSumJp",
              "parent": "7-1",
              "thirdmenu": "総費用ⒿJPY",
              "backgroundColor": "#ffd666" // Replaced with '#ffd666'
            }
          ]
        },
        {
          "key": "7-2",
          "parent": "7",
          "secondmenu": "最小出品価格",
          "backgroundColor": "#ffd666", // Replaced with '#ffd666'
          "children": [
            {
              "key": "amazon",
              "parent": "7-2",
              "thirdmenu": "Amazon_JPY",
              "backgroundColor": "#ffd666" // Replaced with '#ffd666'
            },
            {
              "key": "raKuTen",
              "parent": "7-2",
              "thirdmenu": "楽天_JPY",
              "backgroundColor": "#ffd666" // Replaced with '#ffd666'
            },
            {
              "key": "yaHoo",
              "parent": "7-2",
              "thirdmenu": "Yahoo_JPY",
              "backgroundColor": "#ffd666" // Replaced with '#ffd666'
            },
            {
              "key": "qooTen",
              "parent": "7-2",
              "thirdmenu": "Qoo10_JPY",
              "backgroundColor": "#ffd666" // Replaced with '#ffd666'
            }
          ]
        }
      ]
    },
  ];

  const columns = [
    {
      title: 'カテゴリー1',
      dataIndex: 'firstmenu',
      key: 'firstmenu',
      width: 60,
      onCell: (record) => ({
        style: { backgroundColor: record.backgroundColor },
      }),
    },
    {
      title: 'カテゴリー2',
      dataIndex: 'secondmenu',
      key: 'secondmenu',
      width: 60,
      onCell: (record) => ({
        style: { backgroundColor: record.backgroundColor },
      }),
    },
    {
      title: 'カテゴリー3',
      dataIndex: 'thirdmenu',
      key: 'thirdmenu',
      width: 70,
      onCell: (record) => ({
        style: { backgroundColor: record.backgroundColor },
      }),
    },
    {
      title: 'アクセス権限',
      key: 'viewPowerList',
      width: 40,
      render: (text, record) => (
        <div style={{ backgroundColor: record.backgroundColor, margin: '0px' }}>
          <Checkbox
            checked={checkboxStates[record.key]?.viewPowerList || false}
            onChange={(e) => handleCheckboxChange(e, record, 'viewPowerList')}
          />
        </div>
      ),
    },
    {
      title: '編集の権限',
      key: 'editPowerList',
      width: 35,
      render: (text, record) => (
        <div style={{ backgroundColor: record.backgroundColor }}>
          <Checkbox
            checked={checkboxStates[record.key]?.editPowerList || false}
            onChange={(e) => handleCheckboxChange(e, record, 'editPowerList')}
          />
        </div>
      ),
    },
  ];

  const handleCheckboxChange = (e, record, columnKey) => {
    const { checked } = e.target;
    setCheckboxStates(prevState => {
      // 处理数据，赋值给一个中间变量，最后返回
      const updatedStates = {
        ...prevState,
        [record.key]: {
          ...prevState[record.key], // 保留之前的状态
          [columnKey]: checked,
        },
      };

      const findObjectByKey = (targetKey, data) => {
        const stack = [...data];
        while (stack.length > 0) {
          const node = stack.pop();
          if (node.key === targetKey) {
            return node;
          }
          if (node.children) {
            stack.push(...node.children);
          }
        }
        return null;
      };

      const updateAncestorStates = (ancestorKey) => {
        const ancestorRecord = findObjectByKey(ancestorKey, dataSource);
        console.log('ancestorRecord=', ancestorRecord);
        // datasorce中要有相映数据，不然出bug

        if (updatedStates[ancestorKey]) {
          updatedStates[ancestorKey][columnKey] = false;
        }

        if (ancestorRecord.parent && updatedStates[ancestorRecord.parent]) {
          updatedStates[ancestorRecord.parent][columnKey] = false; // 当某个子孙级复选框被取消勾选时，取消祖先级复选框的勾选状态
        }
      };

      const updateChildStates = (children) => {
        children.forEach(child => {
          const childKey = child.key;
          updatedStates[childKey] = {
            ...prevState[childKey], // 保留之前的状态
            [columnKey]: checked,
          };
          if (child.children) {
            updateChildStates(child.children);
          }
        });
      };

      if (record.children) {
        updateChildStates(record.children);
      }

      if (!checked && record.parent) {
        console.log('record.parent=', record.parent);

        updateAncestorStates(record.parent); // 更新祖先级复选框状态
      }

      return updatedStates;
    });
  };
  const editconfire = async () => {
    // 初始化新的 viewPowerList 和 editPowerList
    let viewPowerList = [];
    let editPowerList = [];

    for (const key in checkboxStates) {
      const element = checkboxStates[key];
      if (element.viewPowerList) {
        viewPowerList.push(key);
      }
      if (element.editPowerList) {
        editPowerList.push(key);
      }
    }

    // 去除重复项
    viewPowerList = [...new Set(viewPowerList)];
    editPowerList = [...new Set(editPowerList)];
    // 输出新的 viewPowerList 和 editPowerList
    console.log("New viewPowerList:", viewPowerList);
    console.log("New editPowerList:", editPowerList);
    const edituserdata = {
      ...selectedUser,
      viewPowerList,
      editPowerList,
      'userPassword': null,
      'delPower': delchecked,
      'disable': disablechecked,
    }

    console.log(edituserdata);
    const editResult = await request.post('/ocis/user/editUser', edituserdata)
    if (editResult.code === 200) {
      message.success(`編集しました`)
    } else {
      message.error(`編集できなかった ${editResult.code}`)
    }

    setFlash(flash + 1);
  }
  // 定义一个函数，用于处理开关状态的变化
  const handleSwitchChange = (checked) => {
    if (checked) {
      setDelchecked(1); // 更新状态变量的值

    } else {
      setDelchecked(0); // 更新状态变量的值

    }
  };

  const disableSwitchChange = (checked) => {
    if (checked) {
      setDisablechecked(1); // 更新状态变量的值

    } else {
      setDisablechecked(0); // 更新状态变量的值

    }
  };


  return (<div>
    <Select style={{ width: 200, marginBottom: 10, marginRight: '10px' }} onChange={handleSelectChange}>
      {options.map(option => (
        <Option key={option.id} value={option.name}>{option.name}</Option>
      ))}
    </Select>

    <span>削除権限</span>
    <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      checked={delchecked} // 将 Switch 的状态与状态变量绑定
      onChange={handleSwitchChange} // 指定状态变化时的处理函数
      style={{ marginLeft: '10px' }}
    />

    <span>無効化</span>
    <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      checked={disablechecked}
      onChange={disableSwitchChange}
      style={{ marginLeft: '10px' }}
    />

    <Button type="primary" onClick={editconfire} style={{ marginLeft: '10px' }}>提交</Button>
    <Table
      className='quanxian-table'
      style={{ width: '50%', fontSize: '14px', fontWeight: 'bold' }}
      dataSource={dataSource}
      columns={columns}
      scroll={{
        y: 700,
      }}
      defaultExpandAllRows
    />
  </div>
  );
}

export default Article;
