// > 业务背景：封装 `AuthRoute` 路由鉴权高阶组件，实现未登录拦截，并跳转到登录页面
// > 实现思路：判断本地是否有token，如果有，就返回子组件，否则就重定向到登录Login
import { getToken } from '../utils'
import { Navigate, useLocation } from 'react-router-dom';

export function AuthRoute({ children }) {
  const isToken = getToken()
  const location = useLocation();
  const currentPath = location.pathname;
  if (!isToken) {
    return <Navigate to="/login" replace />
  } else if (isToken === 'e4080261c63b4bbbaeb94cf8e8e49c58' || isToken === '123457asd456456456aqwe3' || isToken === '35dade20ce9d459a9fc3876d36cdeb94') {
    return <>{children}</>;
  } else {
    // 如果不是 admin，可以根据你的业务逻辑来判断应该允许进入哪些页面
    // 这里只是一个示例，你可以根据实际需求进行修改
    const allowedPages = ['/', '/publish'];
    if (allowedPages.includes(currentPath)) {
      return <>{children}</>;
    } else {
      return <Navigate to="/" replace />;
    }
  }

}