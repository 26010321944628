import { Layout, Menu, Popconfirm, message } from 'antd'
import {
  HomeOutlined,
  DiffOutlined,
  EditOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import './index.scss'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInfo, clearUserInfo } from '@/store/modules/user'

const { Header, Sider } = Layout

const items = [
  {
    label: '首页',
    key: '/',
    icon: <HomeOutlined />,
  },
  {
    label: '权限管理',
    key: '/article',
    icon: <DiffOutlined />,
  },
  {
    label: '分类管理',
    key: '/publish',
    icon: <EditOutlined />,
  },
]

const GeekLayout = () => {
  const navigate = useNavigate()
  const onMenuClick = (route) => {
    const path = route.key
    if ((path === "/article") && (name === "管理者" || name === "大贯" || name === "盛")) {
      navigate(path)
    }
    else if ((path === "/publish") && (name === "管理者" || name === "大贯" || name === "盛" || name === "中村")) {
      navigate(path)
    } else if ((path === "/")) {
      navigate(path)
    } else {
      message.error(`申し訳ございません、権限がありません`)
    }
  }
  //  菜单反向高亮,获取当前路径
  const location = useLocation()
  const selectedKey = location.pathname

  // 触发用户信息action
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchUserInfo())
  }, [dispatch])

  // 退出登录确认回调
  const onConfirm = () => {
    console.log('querentuichu')
    dispatch(clearUserInfo())
    navigate('/login')
  }

  const name = useSelector(state => state.user.userInfo.name)
  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
        <div className="user-info">
          <span className="user-name">{name}</span>
          <span className="user-logout">
            <Popconfirm title="ログアウトしますか？" okText="ログアウト" cancelText="いいえ" onConfirm={onConfirm}>
              <LogoutOutlined /> ログアウト
            </Popconfirm>
          </span>
        </div>
      </Header>
      <Layout>
        <Sider width={60} className="site-layout-background">
          <Menu
            mode="inline"
            theme="dark"
            selectedKeys={selectedKey}
            onClick={onMenuClick}
            items={items}
            style={{ height: '100%', borderRight: 0 }}></Menu>
        </Sider>
        <Layout className="layout-content" style={{ padding: 20 }}>
          {/* 二级路由出口 */}
          <Outlet></Outlet>
        </Layout>
      </Layout>
    </Layout>
  )
}
export default GeekLayout

// import React, { useState } from 'react';
// import {
//   MenuFoldOutlined,
//   MenuUnfoldOutlined,
//   HomeOutlined,
//   DiffOutlined,
//   EditOutlined,
// } from '@ant-design/icons';
// import { Layout, Menu, Button, theme } from 'antd';
// import { Outlet, useNavigate, useLocation } from 'react-router-dom'
// const { Header, Sider, Content } = Layout;
// const GeekLayout = () => {
//   const [collapsed, setCollapsed] = useState(false);
//   const {
//     token: { colorBgContainer, borderRadiusLG },
//   } = theme.useToken();
//   return (
//     <Layout>
//       <Sider trigger={null} collapsible collapsed={collapsed}>
//         <div className="demo-logo-vertical" />
//         <Menu
//           theme="dark"
//           mode="inline"
//           defaultSelectedKeys={['1']}
//           items={[
//             {
//               label: '首页',
//               key: '/',
//               icon: <HomeOutlined />,
//             },
//             {
//               label: '文章管理',
//               key: '/article',
//               icon: <DiffOutlined />,
//             },
//             {
//               label: '创建文章',
//               key: '/publish',
//               icon: <EditOutlined />,
//             },
//           ]}
//         />
//       </Sider>
//       <Layout>
//         <Header
//           style={{
//             padding: 0,
//             background: colorBgContainer,
//           }}
//         >
//           <Button
//             type="text"
//             icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
//             onClick={() => setCollapsed(!collapsed)}
//             style={{
//               fontSize: '16px',
//               width: 64,
//               height: 64,
//             }}
//           />
//         </Header>
//         <Content
//           style={{
//             margin: '24px 16px',
//             padding: 24,
//             minHeight: 280,
//             background: colorBgContainer,
//             borderRadius: borderRadiusLG,
//           }}
//         >
//           {/* 二级路由出口 */}
//           <Outlet></Outlet>
//         </Content>
//       </Layout>
//     </Layout>
//   );
// };
// export default GeekLayout;