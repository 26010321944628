import { createHashRouter } from 'react-router-dom'

import Login from '../pages/Login'
import Layout from '../pages/Layout'
import { AuthRoute } from '../components/AuthRoute'
import Home from '@/pages/Home'
import Publish from '@/pages/Publish'
import Article from '@/pages/Article'


const router = createHashRouter([
  {
    path: '/',
    element: <AuthRoute><Layout /></AuthRoute>,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'article',
        element: <Article />,
      },
      {
        path: 'publish',
        element: <Publish />,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  document.title = "商品管理システム ",
])

export default router