import './index.scss'
import { Card, Form, Input, Button, message } from 'antd'
import logo from '../../assets/logo.png'
import { useDispatch } from 'react-redux'
import { fetchLogin } from '../../store/modules/user'
import { useNavigate } from 'react-router-dom'
import { getToken } from '../../utils'


const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onFinish = async (values) => {
    console.log(values)
    //触发异步action fetchLogin
    await dispatch(fetchLogin(values))

    // 跳转首页
    if (getToken()) {
      navigate('/');
      message.success('ログインしました')
    }
    // 提示用户

  }
  return (
    <div className="login">
      <Card className="login-container">
        <img className="login-logo" src={logo} alt="" />
        {/* 登录表单 */}
        <Form onFinish={onFinish}>
          <Form.Item
            name="userLoginName"
            rules={[
              {
                required: true,
                message: 'アカウントを入力してください!',
              },
            ]}>
            <Input size="large" placeholder="ID" />
          </Form.Item>
          <Form.Item
            name="userPassword"
            rules={[
              {
                required: true,
                message: 'パスワードを入力してください!',
              },
            ]}>
            <Input size="large" placeholder="暗証番号" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" block>
              ログイン
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}
export default Login